.export-orders-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;

  textarea {
    width: 100%;
    min-height: 200px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
  }



  button:hover {
    background-color: #0056b3;
  }

  .export-status {
    margin-top: 10px;
  }

}