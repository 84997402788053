.appbar-wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  max-height: 50px;
  text-align: center;
  font-size: 26px;
  background: var(--main-pallete-darker);
  color: var(--main-pallete-lighter);
  padding: 0 50px;
  font-weight: bold;
}

.appbar-img {
  max-width: 100px;
  cursor: pointer;
}

.appbar-flex-1 {
  flex: 1;
}

.appbar-wrapper .app-version {
  font-size: 14px;
}

@media print {
  .appbar-wrapper{
    display: none;
  }
}