.generalReception {
    padding: 10px 14px 0 0;

    .title {
        font-size: 18px;
        font-weight: bold;
        margin-right: 37px;
    }

    .btn-generalReception {
        padding: 50px 40px;
        text-align: center;
        font-family: Heebo;
        border-radius: 23px;
        cursor: pointer;
        font-size: 16px;
        background: #fff;
        color: #0d7fb1;
        border: 2px solid #0d7fb1;

        &:hover {
            border: 2px solid #29a7df;
            background: #29a7df;
            color: #fff;
        }
    }
}