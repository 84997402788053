.routeAreCodeScreen {
    nav {
        width: 100%;

        ul {
            list-style: none;
            padding: 0 10px 0 0;
            margin: 0;
            display: flex;
            background-color: #f0f0f9;
            border-bottom: 1px solid #ccc;

            li {
                margin: 0;
                padding: 10px;

                a {
                    text-decoration: none;
                    color: #333;
                    font-weight: bold;
                    font-size: 16px;

                    &:hover {
                        color: #007bff;
                    }
                }
            }
        }
    }

    .newRouteAreaCode,
    .editRouteAreaCode {
        width: 400px;
        margin: 20px 20px 0px 0;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }

    h2 {
        margin-bottom: 15px;
    }

    .limit {
        display: flex;
        justify-content: center;
        color: red;
        font-weight: bold;
        font-size: 18px;
    }

    form {
        .form-group {
            display: flex;
            flex-direction: column;


            .input-holder {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
            }

            label {
                display: block;
                margin-bottom: 5px;
                font-weight: bold;
                min-width: 120px;
            }

            input,
            textarea {
                width: 100%;
                padding: 8px;
                border: 1px solid #ccc;
                border-radius: 5px;
                font-size: 16px;
            }

            textarea {
                resize: vertical;
            }

            .select-route {
                width: 100%;
            }

            .error-message {
                color: red;
            }
        }

        .btn-holder {
            display: flex;
            justify-content: center;
        }

        button[type="submit"] {
            padding: 4px 40px;
            font-size: 26px;
            margin: 26px 0 20px 0;
        }
    }
}