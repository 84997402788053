.tn-navigation-tab {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
  background: var(--main-pallete-darker);
  border: 1px solid black;
}

.tn-tabs-wrapper {
  display: flex;
  padding: 0 20px;
  font-size: 25px;
}

.tn-tab {
  font-size: 25px;
  cursor: pointer;
  padding: 20px 10px;
}

.tn-active-tab {
  background-color: var(--main-pallete-lighter);
  font-weight: 800;
}

.tn-single-tab-wrapper {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  min-height: 300px;
}
.tn-single-tab-wrapper.user-orders-tab,
.tn-single-tab-wrapper.user-history-tab {
  display: block;
}

.tn-single-tab-wrapper.user-orders-tab .ol-list-wrapper {
  max-height: 640px;
}
.tn-single-tab-wrapper.user-orders-tab .ols-wrapper-list {
  font-size: 16px;
}
.btn-delete-holder {
}

.btn-delete-holder .btn-delete {
  position: absolute;
  margin-top: 48px;
  padding: 6px 15px;
  border-radius: 6px;
  border: none;
  color: #fff;
  background-color: red;
  font-family: Heebo;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
}

.btn-delete-holder .disabled {
  background-color: #999;
  color: #555;
  cursor: not-allowed;
}
