.pagin-next-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 50px;
}

.pagin-next-wrapper .pagin-btn {
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  max-width: 30px;
  padding: 3px;
  background: #e1e1e1;

  border-radius: 10px;
  color: rgba(0, 0, 0, 0.5);
}

.pagin-next-wrapper .pagin-btn:active,
.pagin-next-wrapper .pagin-btn:hover {
  background-color: #29a7df;
  color: #fff;
}

.pagin-next-wrapper .next-btn {
  max-width: 112px;
  width: 112px;
}

.pagin-next-wrapper .pagin-selected,
.pagin-next-wrapper .pagin-selected:hover {
  background: none;
  color: rgba(0, 0, 0, 0.7);
  cursor: default;
}
