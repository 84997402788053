.order-tracking-modal {

    .modal-body {
        padding: 16px;
    }

    .ss-input {
        margin: 0 !important;
    }

    .error-message {
        color: red;
        font-weight: bold;
        font-size: 20px;
        margin-top: 15px;
    }
}