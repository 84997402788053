.table-editable-text-area {
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0);
    padding: 5px 5px;
    border: 0.3px solid rgba(255, 255, 255, 0);
    width: 100%;
    resize: none;
}

.table-editable-text-area:hover {
    border-color: gray;
}

.highlighted-table-editable-text-area {
    background-color: yellow;
}
