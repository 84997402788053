.table-editable-number-input {
    box-sizing: border-box;
    direction: ltr;
    text-align: center;
    background-color: rgba(255, 255, 255, 0);
    padding: 5px 5px;
    border: 0.3px solid rgba(255, 255, 255, 0);
    width: 80px;
}

.table-editable-number-input:hover {
    border-color: gray;
}

.highlighted-table-number-input {
    background-color: yellow;
}