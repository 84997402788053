.ol-list-wrapper {
    display: flex;
    flex-direction: column;
    max-height: 640px;
    overflow-y: auto;
    padding: 10px 0;
 margin-top: 12px;
}

.ol-list-items-wrapper {
    /* display: flex; */
}

.ol-list-wrapper::-webkit-scrollbar {
    width: 8px;
}

.ol-list-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: #e2e2e2;
}

.ol-list-wrapper::-webkit-scrollbar-thumb {
    background: #666565;
    border-radius: 4px;
}