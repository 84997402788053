.searchOrdersHistoryScreen {
    h1 {
        text-align: center;
        font-size: 28px;
        margin: 20px 0 30px 0;
        color: #333;
        font-weight: 600;
    }

    input {
        padding: 6px 12px;
        border-radius: 8px;
        border: 1px solid #ddd;
        font-size: 16px;
        background-color: #fafafa;
    }

    .input-container {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .select-filter {
            width: 250px;
        }
    }

    .search-filters {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        max-width: 80vw;
        margin: 0 auto;

        label {
            font-size: 14px;
            color: #333;
            font-weight: 600;
        }

        .row-1 {
            width: 100%;
        }


        .row-2 {
            width: 100%;
            display: flex;
            align-items: end;

            .datepicker-container {
                display: flex;
                flex-direction: row;
                gap: 15px;
                background-color: #fff;
                border-radius: 12px;



                .react-datepicker__input-container {
                    width: 100%;
                }

                .react-datepicker__input {
                    border: 1px solid #ddd;
                    padding: 12px;
                    width: 70%;
                    border-radius: 8px;
                    font-size: 16px;
                    background-color: #fafafa;
                    transition: all 0.3s ease;

                    &:focus {
                        border-color: #007bff;
                        background-color: #fff;
                        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
                    }
                }

                .react-datepicker__time-container {
                    display: flex;
                    width: 100%;


                    .react-datepicker__time-box {
                        font-size: 16px;
                    }
                }


            }

            .time-picker-container {
                display: flex;
                flex-direction: column;
                gap: 10px;

                input {
                    width: 100px;
                    padding: 6px 8px;
                }
            }
        }

        .submit-search {
            padding: 7px 22px;
            font-size: 16px;
            margin: 0 18px 0px 0px;
        }

    }

    .spinner {
        margin: 0 38px -8px 0;
    }

    .error-message {
        color: red;
        font-weight: bold;
        font-size: 18px;
        margin: 2rem 6rem 0 0;
    }

    .no-records {
        font-weight: bold;
        font-size: 18px;
        margin: 2rem 6rem 0 0;
    }
}