.not-alive-stations-wrapper {
  display: inline-block;
  padding: 14px 14px 14px 14px;
  width: 630px;
  border: solid #d74b4b 2px;
  border-radius: 12px;
  box-shadow: -4px 13px 31px rgb(26 95 125 / 24%);
  background-color: #fff;
  margin: 0 0 5px 24px;
  h3 {
    color: #d74b4b;
  }
  .down-count {
    display: inline-block;
    min-width: 22px;
    height: 22px;
    font-size: 15px;
    background-color: #d74b4b;
    color: white;
    border-radius: 20px;
    margin: 0 0 0 6px;
    padding: 6px;

    text-align: center;
  }
  .not-alive-stations-list {
    direction: ltr;
    height: 175px;
    overflow: auto;
    padding: 12px 0 0 0;
    .station-item {
      display: flex;
      direction: rtl;
      .column {
        display: flex;
        padding: 3px 6px;
      }
      .number {
        font-weight: bold;
        width: 50px;
      }
      .name {
        width: 200px;
      }
      .before-time {
        font-weight: bold;
        color: #d74b4b;
        font-size: 16px;
        width: 200px;
      }
    }
    .station-item:hover {
      color: rgba(0, 0, 0, 1);
      font-weight: bold;
      background-color: #e5e5e5;
      background-color: rgba(22, 119, 161, 0.05);
    }
  }
  .station-item.title,
  .station-item.title:hover {
    background-color: #e5e5e5;
    .number,
    .name,
    .before-time {
      font-weight: normal;
      color: #333333;
    }
  }

  .not-alive-stations-list::-webkit-scrollbar-track {
    background: rgba(194, 194, 194, 0.23);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    background-clip: padding-box;
  }

  .not-alive-stations-list::-webkit-scrollbar-thumb {
    background: #2faadf;
    border-radius: 11px;
  }
  .not-alive-stations-list::-webkit-scrollbar {
    width: 13px;
  }
}
