.usr-details-wrapper {
    display: flex;
    flex: 1;
    padding: 10px 10px;
    align-items: center;
    /* justify-content: center; */
    white-space: nowrap;
}

.usr-details-modal-input {
    background: #e4e4e4;
    border: 2px solid black;
    direction: ltr;
    margin: 20px 0;
    direction: rtl;
    align-self: flex-start;
    font-size: 25px;
}

.usr-details-txt {
    margin: 0 10px;
    flex: 1;
}