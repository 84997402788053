.order-sacks-list-wrapper {
  .not-printable {
    .title {
      display: flex;
      justify-content: center;
      margin: 16px 0 0 10px;
      font-size: 30px;

      .logo {
        margin: -30px 22px 0px 0;
      }
    }

    .search-wrapper {
      display: flex;
      margin: 18px 18px 18px 0;

      input {
        width: 260px;
        background: #ffffff;
        border: 1px solid #afafaf;
        box-sizing: border-box;
        border-radius: 6px;
        color: #000000;
        font-size: 16px;
        line-height: 23px;
        padding-right: 14px;
        margin-left: 20px;
      }

      .select-account {
        min-width: 260px;

      }
    }

    .list-spinner-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 50px;
    }

    .list-counter {
      padding-right: 22px;
      font-size: 14px;
      color: #8a8a8a;
    }

  }

  .printable {
    display: none;
  }

  @media print {
    align-items: center;
    font-size: 24px;

    .not-printable {
      display: none;
    }

    .printable {
      display: block;

      .barcode-wrapper {
        text-align: center;
        margin: 20px 0 0 0;
      }
    }


  }
}