.inwl-outer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inwl-wrapper {
    border-radius: 10px;
    background-color: #ffffff;
    font-size: 30px;
    margin: 5px;
    padding-right: 15px;
    padding-left: 15px;
    border: 3px solid #ffffff;
    outline: none;
    border: solid #8a8a8a 4px;
}

.inwl-err {
    border: 3px solid #a0220b;
    background-color: #e5c3bd;
}

.inwl-title {
    font-size: 25px;
    text-align: center;
    font-weight: 600;
}

.inwl-mand {
    border: 4px solid #b80a0ad8;
}

.inwl-disabled {
    background: #d6cdcd;
}