.login-modal-wrap {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: none;
  border-radius: 0;
  /* background: rgba(255, 255, 255, 0.95); */
  background: #f4f5f7;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  .login-modal-container {
    display: flex;
    flex-direction: column;
    border: solid 3px white;
    width: 900px;
    background: white;

    .login-modal-inputs-wrapper {
      display: flex;
      flex-direction: column;
      padding: 20px 200px;
      font-size: 25px;
      font-weight: 800;
      text-align: center;

      .login-modal-input {
        background: #e4e4e4;
        border: 2px solid black;
        direction: ltr;
        margin: 30px 0;
      }

      .login-modal-button {
        margin: 20px 100px 0 100px;
      }
    }

    .auth-error {
      color: red;
    }
  }
}

@media (max-width: 650px) {
  .login-modal-wrap {

    .login-modal-container {
      width: 92vw;

      .login-modal-inputs-wrapper {
        padding: 8px 8px 12px 8px;
      }
    }
  }


}