.adt-wrapper {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.adt-inner-wrapper {
  display: flex;
  flex: 1;
}

.adt-brirta-mihdal {
  flex: 1;
}

.station-autocomplete .autocomplete-wrapper {
  padding: 10px 12px 0px 15px;
  max-width: 310px;
}

.station-autocomplete .autocomplete-wrapper .css-1s2u09g-control {
  border: solid #8a8a8a 4px;
}

.station-autocomplete .autocomplete-wrapper .css-1pahdxg-control {
  direction: ltr;
  box-shadow: 0 0 #8a8a8a;
  border: solid #8a8a8a 4px;
}
.station-autocomplete .autocomplete-wrapper .css-1pahdxg-control:hover {
  direction: ltr;
  border: solid #8a8a8a 4px;
}

.station-autocomplete .autocomplete-wrapper .css-1s2u09g-control {
  direction: ltr;
}
.station-autocomplete .autocomplete-wrapper .css-319lph-ValueContainer {
  direction: rtl;
}

.station-autocomplete .autocomplete-wrapper .css-14el2xx-placeholder {
  font-size: 22px;
  font-family: Heebo;
}

.station-autocomplete .autocomplete-wrapper .css-qc6sy-singleValue {
  font-size: 22px;
  color: #8a8a8a;
}

.adt-brirta-mihdal .user-is-monthly-payment {
  color: #df2929e3;
  font-size: 22px;
  font-weight: bold;
  display: inline-block;
  border: solid 1px #df2929e3;
  background-color: #fdb65b;
  padding: 2px 10px;
  border-radius: 20px;
  text-align: center;
}
.ucs-title.default-station-title {
  max-width: 370px;
}
