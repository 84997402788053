.scanSackMobile {
    padding: 10px 14px 0 0;
    text-align: center;

    .title {
        font-size: 18px;
        font-weight: bold;
        margin-right: 12px;
    }

    .btn-scan-mobile {
        width: 128px;
        padding: 38px 0px;
        text-align: center;
        font-family: Heebo;
        border-radius: 23px;
        cursor: pointer;
        font-size: 16px;
        background: #fff;
        color: #0d7fb1;
        border: 2px solid #0d7fb1;

        &:hover {
            border: 2px solid #29a7df;
            background: #29a7df;
            color: #fff;
        }

        img {
            width: 128px;
            height: 118px;
        }
    }
}