#export-section-container {
  background-color: #eee;
  padding: 20px;
  font-family: Roboto;
  font-size: 0.8rem;
}

.export-section-body {
  background-color: white;
  border: 0.3px solid #c4c4c4;
  padding: 20px;
  margin: 15px 0;
}

.export-content {
  display: inline-block;
  font-size: 22px;
}

.download-file-button {
  background-color: #1076ba;
  border: none;
  width: 150px;
  height: 40px;
  color: white;
  padding: 7px 15px;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: inherit;
  font-family: inherit;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}
.order-editor-spinner-wrapper {
  background: rgba(250, 250, 250, 0.6);
  position: absolute;
  z-index: 99;
}
.loading-text {
  position: absolute;
  color: #0c71b8;
  font-size: 1.5rem;
  font-weight: bold;
}
.err-export {
  margin: 2px 25px;
  display: inline-block;
}
