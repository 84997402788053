.validation-message-wrapper{
    display: inline-block;
    position: relative;
}
.validation-message-wrapper .error-text{
    display: inline-block;
    padding: 4px 8px;
    color:red;
    font-weight: bold;
    vertical-align: top;
}

.validation-message-wrapper .fa.fa-check-circle, 
.validation-message-wrapper .fa.fa-times-circle{
    font-size: 22px;
    padding: 6px 0 0 0;
}

.validation-message-wrapper .fa.fa-check-circle{
    color: green;
}

.validation-message-wrapper .fa.fa-times-circle{
    color: red;
}