.update-temporary-password-modal-wrap {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: none;
  border-radius: 0;
  /* background: rgba(255, 255, 255, 0.95); */
  background: #f4f5f7;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;

  .modal-container {
    display: flex;
    flex-direction: column;
    border: solid 3px white;
    width: 900px;
    background: white;

    .inputs-wrapper {
      display: flex;

      flex-direction: column;
      padding: 20px 200px;
      font-size: 25px;
      font-weight: 800;
      text-align: center;
      .input-wrapper {
        position: relative;
        display: flex;
        align-self: center;
        .psw-input {
          background: #e4e4e4;
          border: 2px solid black;
          direction: ltr;
          margin: 30px 0;
          text-align: center;
        }
        .password-eye {
          position: absolute;
          right: -4rem;
          top: 2.3rem;
          cursor: pointer;
        }
      }
    }

    .modal-button {
      margin: 20px 100px 0 100px;
    }
    .auth-error {
      color: red;
      font-size: 16px;
    }
  }
}
