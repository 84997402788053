$primary-color: #29a7df;
$secondary-color: #fda73a;
$disabled-color: rgba(0, 0, 0, 0.5);

.blbtn-wrapper {
  cursor: pointer;
  border-radius: 5px;
  background-color: $primary-color;
  text-align: center;
  color: #ffffff;
  font-family: Heebo;
  font-size: 39px;
  font-weight: 400;
  user-select: none;
  margin: 5px;
  border: none;

  &:active {
    background-color: $secondary-color;
  }

  &.disabled {
    cursor: not-allowed;
    background-color: $disabled-color;

    &:hover {
      border: none;
      background-color: $disabled-color;
      color: $disabled-color;
    }
  }
}

.btn-table {
  font-size: 16px;
  border-radius: 10px;
  padding: 4px 0px;
  font-weight: 400;
  letter-spacing: 1px;
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: $disabled-color;
  margin: 0;
  cursor: pointer;
  width: 100px;
  margin: 2px;

  &:hover {
    border: solid $primary-color 1px;
    background-color: $primary-color;
    color: #fff;
  }
}