.uls-wrapper-list {
  font-size: 14px;
  text-align: center;
  max-height: 700px;
  min-height: 400px;
  overflow-y: auto;
  padding: 10px 0;
}

.uls-wrapper-list::-webkit-scrollbar {
  width: 8px;
}

.uls-wrapper-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #e2e2e2;
}

.uls-wrapper-list::-webkit-scrollbar-thumb {
  background: #666565;
  border-radius: 4px;
}

.uls-slct-input {
  border: solid #8a8a8a 2px;
  border-radius: 5px;
  margin: 10px 20px;
  font-size: 16px;
}

.uls-btn-wrapper {
  display: flex;
  width: 600px;
  justify-content: right;
}

.uls-btn {
  font-size: 14px;
  width: 40px;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  background-color: var(--main-pallete-darker);
}

.search-btn {
  display: inline-block;
  height: auto;
  font-size: 20px;
  min-width: 80px;
  width: 180px;
  padding: 4px 12px;
  margin: 10px 12px 0px 15px;
  box-shadow: 0 0 #8a8a8a;
  font-weight: bold;
}

.base-btn:hover {
  border: solid #29a7df 1px;
  background-color: #29a7df;
  color: #fff;
}
.export-wrapper .base-btn {
  display: inline;
  margin: 0 12px;
}
.user-list-counter {
  padding-right: 22px;
  font-size: 14px;
  font-weight: bold;
  color: #8a8a8a;
}
