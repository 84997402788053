.mul-selvalpassive-wrapper {
  display: flex;
  flex: 1;
  font-size: 18px;
  font-family: Heebo;
  font-weight: 500;
  flex-direction: column;
  user-select: none;
  /* border: 1px solid #8a8a8a; */
  /* border-radius: 10px; */
  /* margin: 0 4px; */
  text-align: center;
}

.mul-selvalpassive-displayed-itm {
  display: flex;
  background: white;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  /* border-radius: 10px; */
  min-height: 40px;
}

.mul-selvalpassive-arrow-down {
}

.mul-selvalpassive-arrow-img {
  display: flex;
  width: 15px;
  height: 15px;
  align-self: center;
  justify-content: center;
}

.mul-selvalpassive-displayed-itm:hover {
  font-weight: 800;
}

.mul-selvalpassive-itms-list-wrapper {
  position: relative;
  flex: 1;
}

.mul-selvalpassive-itms-list {
  position: absolute;
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow: auto;
  width: 100%;
}

.mul-selvalpassive-itm {
  display: flex;
  flex: 1;
  /* justify-content: center; */
  /*margin: 0 15px;*/
  padding: 6px;
}

.mul-selvalpassive-itm-extra {
  background: white;
  border: 1px solid #8a8a8a;
  border-top: 0;
}

.mul-selvalpassive-itm-extra:hover {
  background: #1076ba;
  color: white;
  font-weight: 600;
}

.mul-selvalpassive-picker {
  color: white;
  background: #1076ba;
  border: 0px solid black;
  font-weight: 500;
}

.mul-selvalpassive-itms-list::-webkit-scrollbar {
  width: 12px;
}

.mul-selvalpassive-itms-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: white;
}

.mul-selvalpassive-itms-list::-webkit-scrollbar-thumb {
  background: #8a8a8a;
  border-radius: 10px;
}

.mul-selvalpassive-checkbox {
  width: 18px;
  height: 18px;
  justify-self: flex-start;
  align-self: center;
}

.mul-selvalpassive-itm-content {
  align-self: center;
  flex: 1;
  padding-right: 12px;
  text-align: right;
}
