.noti-modal-wrap {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: none;
    border-radius: 0;
    background: #f4f5f7a8;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 6;
}

.noti-modal-container {
    display: flex;
    flex-direction: column;
    border: solid 3px white;
    width: 900px;
    background: white;
}

.noti-modal-content-wrapper.notes {
    display: flex;
    flex-direction: column;
    padding: 50px 50px;
    padding-bottom: 20px;
    font-size: 25px;
    font-weight: 800;
    text-align: center;
}

.noti-modal-button {
    margin: 0;
    padding: 10px 30px;
    font-size: 23px;
}
.notes-wrapper{
    max-height: 420px;
    overflow-y: auto;
}
.note-row{
    display: block;
    width: 750px;
    padding: 12px;
}
.note-row:nth-child(even) {
    background-color: #f2f2f2;
}
.note-row.title{ 
    padding-bottom: 34px;
}
.note-col-ind{
    display: inline-block;
    width: 38px;
    padding:8px;
    border-radius: 32px;
    background-color:#29a7df;
}
.note-col{
    display: inline-block;
    text-align: right;
    vertical-align: middle;
    padding: 0 14px 0 0;
    width: 562px;
}

