/* Price lists main */
#reports-section-container {
    background-color: #eee;
    padding: 20px;
}

.reports-section-body {
    background-color: white;
    border: 0.3px solid #c4c4c4;
    padding: 20px;
    margin: 15px 0;
}

#reports-section-menu {
    padding: 15px 0;
    background-color: white;
    border: 0.3px solid #c4c4c4;
    margin: 0 0 20px 0;
    font-size: 1rem;
}

.reports-section-menu-item {
    display: inline-block;
    margin: 0 20px;
}

.reports-section-menu-item a {
    text-decoration: none;
    padding: 7px;
}


.form-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0 0 15px 0;
}

.reports-section-header {
    display: inline-block;
    margin: 0 0 20px 20px;
}

.report-section-labeled-text-input {
    margin: 0 0 0 15px;
}

.report-section-labeled-text-input label {
    display: block;
    font-size: 0.9rem;
    color: #aaa;
}

.report-section-labeled-text-input input {
    min-width: 150px;
    border: 1px solid #aaa;
    border-radius: 5px;
    padding: 5px;
    font-size: 1rem;

}