.stationTaskHistory {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    .modal {
        top: 9rem;
        left: 4rem;
        right: 23rem;
        bottom: 40px;
        background-color: white;
        padding: 20px;
        border-radius: 4px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        max-width: 60%;
        max-height: 66%;
        overflow: hidden;

        .title {
            font-size: 2rem;
            text-align: center;
        }

        .table-container {
            overflow: auto;
            max-height: calc(48vh);

        }

        .history-table {
            width: 100%;
            border-collapse: collapse;
            border: 1px solid #ccc;
            font-family: Arial, sans-serif;
            font-size: 16px;
            overflow: scroll;

            th,
            td {
                text-align: right;
                padding: 10px;
                border: 1px solid #ccc;
            }

            th {
                background-color: #f2f2f2;
                font-weight: bold;
                white-space: nowrap;
            }

            td {
                vertical-align: top;
            }

            td:nth-child(1),
            td:nth-child(2) {
                width: 1%;
            }

            td:nth-child(4),
            td:nth-child(5) {
                width: 20%;
            }

            td:nth-child(7) {
                width: 12%;
                text-align: center;
            }

            .btn-pic {
                font-size: 16px;
                border-radius: 10px;
                padding: 3px 12px;
                font-weight: 400;
                letter-spacing: 1px;
                background: none;
                border: solid rgba(0, 0, 0, 0.5) 1px;
                color: rgba(0, 0, 0, 0.5);
            }

            .btn-pic:hover {
                border: solid #29a7df 1px;
                background-color: #29a7df;
                color: #fff;
            }
        }

        .btn-holder {
            display: flex;
            justify-content: center;

            button {
                padding: 4px 40px;
                font-size: 26px;
                margin: 26px 0 20px 0;
            }
        }

        .no-history {
            text-align: center;
            font-size: 2rem;
        }
    }

}