.infl-wrapper {
    border-radius: 10px;
    background-color: #ffffff;
    font-size: 30px;
    margin: 5px;
    padding-right: 15px;
    padding-left: 15px;
    border: 3px solid #ffffff;
    outline: none;
}

.infl-err {
    border: 3px solid #a0220b;
    background-color: #e5c3bd;
}
