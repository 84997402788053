.white-frame-wrapper {
  display: flex;
  /* justify-content: space-between; */
  background: white;
  border: solid 1px #e4e4e4;
  padding: 10px 30px;
  white-space: nowrap;
}

.white-frame-child-wrapper {
  display: flex;
  flex: 1;
}

.white-frame-child-seperator {
  /* padding: 0px 40px; */
  flex: 1;
  border-right: 2px solid #dcdcdc;
}

.white-frame-child {
  flex: 10;
}
