.station-status-wrapper {
    z-index: 2;

    .btn-back-wrapper {
        display: flex;
        justify-content: end;
        padding: 0 0 0 36px;
        margin: 0 0 12px 0;

        .btn-back {
            padding: 4px 8px;
            background-color: #29a7df;
            text-align: center;
            color: #ffff;
            border: none;
            font-size: 22px;
        }
    }
}