.generalReceptionPage {
  padding: 1vh 6vh;

  .header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    .order-input {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      h3 {
        margin-left: 15px;
      }

      .input-text {
        display: flex;
        flex-grow: 1;
        font-size: 18px;
        padding: 4px 2px;
        min-width: 300px;

      }

      .btn-reset-table {
        font-size: 1vw;
        width: 106px;
        height: 39px;

      }
    }

    .btn-area-select {
      width: 236px;
      font-size: 20px;
      padding: 6px;
    }

    .btn-wrapper {
      display: flex;
      justify-content: center;
      margin: 8px 0 0 0;
      border: 1px solid grey;
      border-radius: 8px;

      .btn-regular {
        font-size: 16px;
        padding: 6px 8px;
      }

      .btn-delay {
        padding: 4px 6px;
        font-size: 16px;
        background-color: orange;
        color: #333;
      }

      .btn-gamestorm {
        margin: 0 12px 0 0;
        font-size: 1vw;
        padding: 6px 12px;
      }

      .btn-end-day {
        background-color: #05b409;
      }
    }

    .accepted-order {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 6px 14px;
      border-radius: 8px;
      margin-right: 20px;
      min-width: 56%;
      border: 16px solid;
      border-color: var(--border-color, grey);
      font-size: 18px;
      flex-grow: 1;

      .destination {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 6px;
        color: var(--border-color, black);

      }

      .order-params {
        align-items: center;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        border-right: solid 1.5em transparent;

        .params-col {
          position: relative;
          margin: 0.5em 1.5em;
          word-wrap: break-word;
          max-width: 300px;
        }

        hr {
          height: 50px;
          margin: auto -20px;
          border: none;
          border-left: 1px solid lightgray;
        }
      }
    }

    .count {
      position: absolute;
      right: -30px;
      top: -24px;
      border-radius: 50%;
      width: 28px;
      height: 26px;
      padding: 8px;
      background-color: var(--border-color, grey);
      -webkit-box-shadow: 1px 4px 7px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
      text-align: center;
      font-size: 20px;
    }

    .area-code-select {
      margin-top: 8px;
    }
  }


  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .fade-in {
    animation: fadeIn 0.5s ease-in forwards;
    opacity: 0;
  }

}

.noti-modal-container .reception-notes-list {
  text-align: right;
}