.noti-modal-wrap {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: none;
    border-radius: 0;
    background: #f4f5f7a8;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 6;

    .noti-modal-container {
        display: flex;
        flex-direction: column;
        border: solid 1px black;
        width: 900px;
        background: white;
        box-shadow: 3px 1px 15px 0px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 3px 1px 15px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 3px 1px 15px 0px rgba(0, 0, 0, 0.75);
    }

    .noti-modal-content-wrapper {
        display: flex;
        flex-direction: column;
        padding: 50px 200px;
        padding-bottom: 20px;
        font-size: 25px;
        font-weight: 800;
        text-align: center;
    }

    .noti-btn-wrapper {
        text-align: center;
        margin: 0px 0px 16px 0;

        .noti-modal-button {
            margin: 0;
            padding: 10px 30px;
            font-size: 23px;
        }
    }


}

@media (max-width: 650px) {
    .noti-modal-wrap {

        .noti-modal-container {
            width: 88vw;
        }

        .noti-modal-content-wrapper {
            padding: 10px 8px 18px 8px;
            font-size: 22px;
        }
    }


}