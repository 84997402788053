.laundry-images-modal {
    .modal-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5rem 4rem 4rem 4rem;
        width: 560px;
        height: 500px;
    }

    .title {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 16px;
    }

    .carousel-holder {
        .swiper {
            width: 100%;
            height: 100%;
        }

        .swiper-slide {
            text-align: center;
            font-size: 18px;
            background: #fff;

            /* Center slide text vertically */
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .swiper-slide .item_img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;

            img {
                height: 480px;
                width: 380px;
            }
        }

        .loading {
            text-align: center;
            direction: ltr;
        }
    }

    .btn-holder {

        margin: 18px 0 16px 0;
        padding: 0px 168px;

        .blbtn-wrapper {
            font-size: 28px;
        }
    }
}