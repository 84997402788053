.order-itms-table-frame {
  /* UNCOMMENT TO EXPAND THE TABLE */
  min-width: 1300px;
  /* display: flex; */
  /* flex: 1; */
}

.order-itms-table-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.qwe-blue-title {
  color: #29a7df;
  font-size: 21px;
  display: flex;
  align-items: center;
}
.delivery-code-wrapper {
  display: flex;
  color: #05b409;
  background-color: #f4f5f7;
  margin: 0 20px;
  padding: 0 16px;
  border-radius: 5px;
  align-items: center;
}
.info-wrapper {
  display: inline-block;
  font-weight: bold;
  background-color: #eee;
  margin: 2px 6px;
  padding: 0 12px;
  border-radius: 5px;
}

.info-wrapper-btn {
  display: inline-block;
  margin: 2px 6px;
  padding: 5px 10px;
  color: green;
  cursor: pointer;
}

.title-wrapper {
  display: flex;
  align-items: baseline;
}
.btn-send-sms-holder {
  width: auto;
  margin: 0 6px;
}

.btn-items-original {
  font-size: 16px;
  padding: 4px 16px;
  font-weight: 600;
}

.btn-reset-locker-code {
  font-size: 16px;
  padding: 4px 16px;
  font-weight: 600;
  background-color: red;
}

.title-wrapper .order-data-value {
  font-size: 21px;
}

.order-data-external {
  display: inline-flex;
  background-color: #f4f5f7;
  border-radius: 5px;
  max-width: 220px;
  max-height: 30px;
  overflow: hidden;
  padding: 0 8px;
  direction: ltr;
  font-size: 20px;
}

.order-itms-table-wrapper .laundry-buttons {
  display: flex;
}
