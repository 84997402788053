.cnoritm-table-item {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 2px 0;
    white-space: initial;
}

.cnoritm-table-itm-elem {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    color: black;
    font-size: 16px;
    border: 1px solid #8a8a8a;
    border-radius: 5px;
    margin: 0;
}

.cnot-table-input-extended {
    flex: 2;
}

.cnoritm-table-itm-elem-btn-wrap {
    justify-content: center;
    align-items: center;
    flex: 1;
    color: black;
    font-size: 16px;
}

.cnoritm-table-elem-btn {
    font-size: 16px;
    padding: 3px 5px;
    font-weight: 600;
    background: #05b409;
}

.cnoritm-table-item .css-b62m3t-container{
    width: 135px;
}