.cror-modal-wrap {
  .cror-modal-container {
    .change-package-number-modal {
      .modal-body {
        padding: 12px 48px;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        div {
          margin-bottom: 0.5rem;
        }

        .input-text {
          margin: 0.5rem 0.5rem 0 0;
          padding: 0.40rem;
          border: 1px solid lighten(#2c3e50, 40%);
          border-radius: 4px;
          font-size: 1rem;
          transition: border-color 0.3s ease;

          &:focus {
            outline: none;
            border-color: #3498db;
            box-shadow: 0 0 0 2px rgba(#3498db, 0.2);
          }
        }

        .validation-msg {
          color: red;
          font-size: 0.9rem;
        }

        .cror-modal-status {
          display: flex;
          justify-content: space-between;

          .cror-modal-button {
            margin: 0 1rem;
            font-size: 24px;
          }
        }
      }
    }
  }
}