.user-edit-others-tab {
  .registration-date {
    direction: ltr;
    text-align: center;
  }
  .date-picker-wrapper {
    min-width: 340px;
    input {
      font-size: 25px;
      margin: 40px 16px 0 0;
    }
    .date-picker-label {
      font-size: 25px;
      font-weight: bold;
      top: 0;
    }
  }
}
