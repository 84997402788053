.modal {
    position: fixed;
    display: block;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    border: 1px solid #ccf;
    background: #fff;
    overflow: auto;
    border-radius: 10px;
}