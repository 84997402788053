.select-sack-modal {
  .btn-table {
    margin: 2px 26px 2px 0;
  }
  .sack-list-wrapper {
    max-width: 1000px;
    .btn-table {
      position: relative;
      display: inline-block;
      margin: 12px 6px;
      padding: 6px 12px;
      .orders-count {
        position: absolute;
        display: inline-block;
        min-width: 22px;
        min-height: 20px;
        padding: 6px;
        top: -20px;
        right: -5px;
        border-radius: 20px;
        font-weight: bold;
        color: #555;
        background-color: #fda73a;
        font-size: 13px;
      }
    }
  }
  input {
    width: 260px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #afafaf;
    box-sizing: border-box;
    border-radius: 6px;
    color: #000000;
    font-size: 16px;
    line-height: 23px;
    padding-right: 14px;
    margin: 0 26px 26px 0px;
  }
}
