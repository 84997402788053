.dhl-order-to-locker {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(244, 245, 247, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;

    .success {
        margin: 18px 8px;
        text-align: center;

        button {
            margin-top: 18px;
            padding: 8px 28px;
            font-size: 22px;
        }

    }

    .cror-modal-container {
        min-width: 520px;
        margin: 0 auto;
        padding: 20px;
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .cror-modal-inputs-wrapper {
        margin-top: 20px;

        form {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .spinner-holder {
                align-items: center;
            }

            div {
                display: flex;
                flex-direction: column;

                input {
                    padding: 10px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    font-size: 16px;
                    direction: rtl;

                    &:focus {
                        outline: none;
                        border-color: #007bff;
                        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
                    }
                }

                .error {
                    color: #e74c3c;
                    font-size: 14px;
                    margin-top: 5px;
                }
            }

            .btn-modal-holder {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;

                button {
                    flex: 1;
                    padding: 12px 20px;
                    border: none;
                    border-radius: 4px;
                    font-size: 16px;
                    font-weight: bold;
                    cursor: pointer;
                    margin: 0;

                    &:first-child {
                        background-color: #29a7df;
                        color: #ffffff;
                        margin-left: 10px;

                        &:hover:not(:disabled) {
                            background-color: #3a7bbf;
                        }

                        &:disabled {
                            background-color: #a0c8ff;
                            cursor: not-allowed;
                        }
                    }

                    &:last-child {
                        background-color: #f5f5f5;
                        color: #333;

                        &:hover {
                            background-color: #e8e8e8;
                        }
                    }
                }
            }
        }
    }

    .form-error {
        color: #e74c3c;
        font-size: 22px;
        margin-top: 15px;
    }
}