.item-wrapper {
  display: flex;
  text-align: center;
  padding: 1px;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  background-color: rgba(244, 244, 244, 0.3);
  color: rgba(0, 0, 0, 1);
  font-size: 16px;

  .circle {
    display: inline-block;
    border-radius: 16px;
    padding: 4px;
    width: 18px;
    height: 18px;
    margin: 5px 5px 0 0;
  }
}

.item-wrapper:hover {
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
  background-color: #e5e5e5;
  background-color: rgba(22, 119, 161, 0.05);
}

.item-last {
  /* border-bottom: rebeccapurple solid 1px; */
}

.remove-driver-btn {
  font-size: 16px;
  border-radius: 10px;
  padding: 3px 16px;
  font-weight: 400;
  letter-spacing: 1px;
  background: none;
  border: solid rgba(0, 0, 0, 0.5) 1px;
  color: rgba(0, 0, 0, 0.5);
}

.item-wrapper:hover .remove-driver-btn {
  border: solid rgba(0, 0, 0, 1) 1px;
  color: rgba(0, 0, 0, 1);
}

.item-wrapper:hover .remove-driver-btn:hover {
  border: solid #29a7df 1px;
  background-color: #29a7df;
  color: #fff;
}

.item-wrapper:hover .remove-driver-btn:active {
  border: solid #29a7df 1px;
  background-color: #29a7df;
  color: #fff;
}

.num {
  background: rgb(41 167 223 / 20%);
  font-size: 14px;
}

.clickable {
  cursor: pointer;
}

.item-wrapper .order-na {
  color: rgba(0, 0, 0, 0.2);
}
.fa-wheelchair {
  font-size: 26px;
  color: #05b409;
  padding: 0 0 0 5px;
}
