.item-wrapper {
  display: flex;
  text-align: center;
  padding: 1px;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  background-color: rgba(244, 244, 244, 0.3);
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
}

.item-wrapper:hover {
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
  background-color: #e5e5e5;
  background-color: rgba(22, 119, 161, 0.05);
}

.item-last {
  /* border-bottom: rebeccapurple solid 1px; */
}

.set-locker-to-empty-btn,
.relocate-btn {
  font-size: 16px;
  border-radius: 10px;
  padding: 3px 16px;
  font-weight: 400;
  letter-spacing: 1px;
  background: none;
  border: solid rgba(0, 0, 0, 0.5) 1px;
  color: rgba(0, 0, 0, 0.5);
}
.item-wrapper:hover .set-locker-to-empty-btn,
.item-wrapper:hover .relocate-btn {
  border: solid rgba(0, 0, 0, 1) 1px;
  color: rgba(0, 0, 0, 1);
}

.item-wrapper:hover .set-locker-to-empty-btn:hover,
.item-wrapper:hover .relocate-btn:hover {
  border: solid #29a7df 1px;
  background-color: #29a7df;
  color: #fff;
}

.item-wrapper:hover .set-locker-to-empty-btn:active,
.item-wrapper:hover .relocate-btn:active {
  border: solid #29a7df 1px;
  background-color: #29a7df;
  color: #fff;
}

.clickable {
  cursor: pointer;
}
