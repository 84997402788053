// YourFormComponent.scss

.BranchCard {
    margin: 22px 20px 0 40px;

    h2 {
        text-align: center;
        margin-bottom: 20px;
    }

    form {
        display: flex;
        flex-direction: column;

        .inputs-holder {
            display: flex;
            justify-content: space-between;

            .select-payment-type {
                margin-bottom: 15px;
            }

            div {
                max-width: 18vw;
            }
        }

        .fileInputButton {
            display: inline-block;
            padding: 6px 14px;
            font-size: 16px;
            font-weight: bold;
            color: #fff;
            background-color: #1076ba;
            border: 1px solid #1076ba;
            border-radius: 5px;
            cursor: pointer;
            text-align: center;
            margin: 0px 0 12px 12px;
        }

        input {
            margin-bottom: 15px;
            padding: 8px;
            border: 1px solid #ccc;
            border-radius: 4px;
            width: 95%;

            &:focus {
                outline: none;
                border-color: #007bff;
                box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
            }
        }

        .file-input-button {
            padding: 4px 8px;
        }

        .contract {
            display: flex;
            margin-bottom: 15px;

            input {
                margin-bottom: 0;
            }

            .btn-download {
                font-size: 20px;
                padding: 0px 12px;
                background-color: #4CAF50;
                margin: 0 8px 0 0;
            }
        }

    }

    .btn-edit {
        padding: 2px 16px;
        font-size: 24px;
    }
}