.orders-history-table {
    width: 96%;
    margin: 1rem auto;
    font-family: Arial, sans-serif;

    table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #ddd;
        font-size: 14px;

        thead {
            background-color: #f4f4f4;

            th {
                padding: 10px;
                text-align: right;
                font-weight: bold;
                color: #333;
                text-transform: uppercase;
                letter-spacing: 0.05em;
                border-bottom: 2px solid #ddd;
            }
        }

        tbody {
            tr {
                &:nth-child(even) {
                    background-color: #fafafa;
                }

                &:hover {
                    background-color: #f1f1f1;
                }

                td {
                    padding: 10px;
                    border-bottom: 1px solid #ddd;
                    color: #555;
                }
            }
        }
    }

    .pagination {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        button {
            padding: 8px 12px;
            border: 1px solid #ddd;
            border-radius: 4px;
            background-color: #fff;
            color: #333;
            font-size: 14px;
            font-weight: bold;
            cursor: pointer;
            transition: background-color 0.3s, color 0.3s;

            &:hover {
                background-color: #007bff;
                color: #fff;
                border-color: #007bff;
            }

            &.active {
                background-color: #007bff;
                color: #fff;
                border-color: #007bff;
            }
        }
    }
}