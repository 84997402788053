.SacksScreen {
    .section-menu {
        padding: 4px 4px;
        background-color: #f0f0f0;
        border-bottom: 1px solid #ccc;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
        }

        .section-menu-item {
            margin-right: 16px;
            display: inline-block;

            a {
                text-decoration: none;
                color: #000;
                font-weight: bold;

                &:hover {
                    text-decoration: none;
                    color: grey
                }
            }
        }
    }

    @media print {
        .section-menu {
            display: none;
        }
    }
}