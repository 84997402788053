.closeOrdersSackPage {
    padding: 20px;
    background-color: #f9fafb;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    min-height: 93vh;

    h2 {
        font-size: 24px;
        margin-bottom: 20px;
        color: #333;
        text-align: center;
    }

    .spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2rem 0 0 0;
    }

    .form-wrapper {
        display: flex;
        align-items: baseline;

        .form-group {
            margin: 0;


            .select-wrapper {
                font-size: 26px;
                width: 300px;
            }
        }

        .button-wrapper {
            text-align: center;

            .btn-close-sack,
            .btn-refresh,
            .btn-close-all,
            .btn-print-barcode {
                background-color: #007bff;
                color: #fff;
                border: none;
                padding: 6px 20px;
                font-size: 28px;
                border-radius: 4px;
                cursor: pointer;

                &:disabled {
                    background-color: #6c757d;
                    cursor: not-allowed;
                }
            }

            .btn-close-all {
                background-color: #cc3300;
            }

            .btn-close-sack {
                background-color: #05b409;
            }

        }
    }


    .orders-list {
        margin-top: 30px;

        h3 {
            font-size: 20px;
            margin-bottom: 15px;
            color: #333;
            text-align: center;
        }

        .orders-table {

            width: 100%;
            border-collapse: collapse;
            margin-top: 10px;

            thead {
                background-color: #F7F7F7;
                color: black;

                th {
                    padding: 12px;
                    text-align: right;
                    border: 1px solid #dee2e6;
                }
            }

            tbody {
                tr {
                    &:nth-child(even) {
                        background-color: #f8f9fa;
                    }

                    td {
                        padding: 12px;
                        border: 1px solid #dee2e6;
                    }
                }
            }
        }
    }
}