/* Price lists main */
#price-lists-section-container {
  background-color: #eee;
  padding: 20px;
  font-family: Roboto;
  font-size: 0.8rem;
}

#price-lists-section-body {
  background-color: white;
  border: 0.3px solid #c4c4c4;
  padding: 20px;
}

#price-lists-section-menu {
  padding: 15px 0;
  background-color: white;
  border: 0.3px solid #c4c4c4;
  margin: 0 0 20px 0;
  font-size: 1rem;
}

.price-lists-section-menu-item {
  display: inline-block;
  margin: 0 20px;
}

.price-lists-section-menu-item a {
  text-decoration: none;
  padding: 7px;
}
.list-link-holder {
  display: flex;
  margin: 8px 0 10px 0;
}
/* Price lists section */
.price-list-section-price-list-link {
  list-style: none;
  padding: 4px 8px;
  margin-left: 16px;
  font-size: 16px;
  cursor: pointer;
}

.list-link-holder a {
  text-decoration: none;
}

#items-section-header,
#processes-section-header,
#services-section-header,
#price-lists-section-header {
  display: inline-block;
  margin: 0 0 20px 20px;
}

/* .save-processes-button {
    box-sizing: border-box;
    display: inline-block;
    color: white;
    padding: 7px 25px;
    background-color: #1076ba;
    border: 0;
    border-radius: 5px;
} */

#items-table,
#processes-table,
#products-price-list-table,
#laundry-price-list-table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

#items-table tr,
#processes-table tbody tr,
#products-price-list-table tbody tr,
#laundry-price-list-table tbody tr {
  border-bottom: 1px solid gray;
}

#items-table th,
#processes-table th,
#products-price-list-table th,
#laundry-price-list-table th {
  background: #eee;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  position: sticky;
  top: 0;
  font-weight: 700;
  text-align: start;
  padding: 5px;
}

#items-table td,
#processes-table td,
#products-price-list-table td,
#laundry-price-list-table td {
  padding: 5px;
}
/* Items table columns */
#items-table th:nth-child(3),
#items-table th:nth-child(4) {
  text-align: end;
}

#items-table td:nth-child(3),
#items-table td:nth-child(4) {
  direction: ltr;
}

#items-table th:nth-child(6),
#items-table td:nth-child(6) {
  text-align: center;
}

/* Processes table columns */
#processes-table th:nth-child(1),
#processes-table th:nth-child(2),
#processes-table th:nth-child(7) {
  width: 5%;
}

#processes-table th:nth-child(4),
#processes-table th:nth-child(5) {
  text-align: end;
}

#processes-table td:nth-child(4),
#processes-table td:nth-child(5) {
  direction: ltr;
}

#processes-table th:nth-child(8),
#processes-table th:nth-child(9),
#processes-table th:nth-child(10),
#processes-table th:nth-child(11) {
  width: 7%;
  text-align: center;
}

#processes-table td:nth-child(1),
#processes-table td:nth-child(2),
#processes-table td:nth-child(8),
#processes-table td:nth-child(9),
#processes-table td:nth-child(10),
#processes-table td:nth-child(11) {
  text-align: center;
}

/* Laundry price list table columns */
#laundry-price-list-table th {
  text-align: center;
}
#laundry-price-list-table th:nth-child(1) {
  text-align: start;
}

#laundry-price-list-table td {
  text-align: center;
}
#laundry-price-list-table td:nth-child(1) {
  text-align: start;
}
#products-price-list-table th:nth-child(3),
#products-price-list-table td:nth-child(3) {
  text-align: center;
}

.process-table-editable-select {
  border: none;
  background-color: rgba(255, 255, 255, 0);
}

.process-table-editable-select:hover {
  box-sizing: border-box;
  border: 0.5px solid gray;
}

.price-list-item-img,
.process-img {
  height: 50px;
}

.delete-button {
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #bf1722;
  padding: 7px 15px;
  border-radius: 5px;
  color: #bf1722;
}

.delete-button-disabled {
  border: 1px solid gray;
  color: gray;
}

#delete-price-list-button {
  margin: 0 20px;
}

#price-list-select {
  display: inline;
}

#laundry-price-list-table .priceHolder {
  display: flex;
  align-items: center;
}
#laundry-price-list-table .priceHolder {
  margin-left: 8px;
}
#laundry-price-list-table .priceHolder .btn-save {
  margin: 0 8px 0 8px;
  padding: 5px 14px;
  color: green;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 7px;
  border: 1px solid green;
  background-color: white;
}

#laundry-price-list-table .priceHolder .btn-save:disabled {
  border: 1px solid #999999;
  color: #666666;
  cursor: not-allowed;
  pointer-events: all !important;
}

#price-lists-section-body .btn-add-prod {
  box-sizing: border-box;
  background-color: white;
  border: 1px solid green;
  padding: 7px 15px;
  border-radius: 5px;
  color: green;
  cursor: pointer;
}

#price-lists-section-body .createProduct {
  margin-bottom: 14px;
}

#price-lists-section-body .createProduct input {
  margin-left: 12px;
  padding: 4px 4px;
}

#price-lists-section-body .createProduct .btn-save {
  background-color: #05b409;
  border: none;
  padding: 6px 15px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

#price-lists-section-body .btn-holder {
  display: inline-block;
}

#price-lists-section-body .btn-holder .blbtn-wrapper {
  font-size: 16px;
  padding: 4px 12px;
}

#price-lists-section-body .table-holder {
  overflow-y: scroll;
  height: 700px;
  display: block;
}
