#services-table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin: 20px 0 0 0;
}

#services-table th {
    background: #eee;
    position: sticky;
    top: 0;
    text-align: start;
    padding: 5px;
}

#services-table td {
    margin: 0;
    padding: 5px;
}

#services-table tr {
    border-bottom: 1px solid gray;
}