.stationTasksList {
    .title {
        text-align: center;
        width: 100%;
        font-size: 22px;
        font-weight: bold;
        margin: 0px 0 12px 0;
    }

    .noTasks {
        text-align: center;
        margin-top: 20px;
        font-size: 18px;
        font-weight: bold;
    }

    .search-bar {
        display: flex;
        margin-bottom: 20px;

        .search-input {
            width: 20rem;
            padding: 0px 8px;
            font-size: 16px;
            border: 1px solid #ccc;
            border-radius: 4px;
        }

        .select-status {
            width: 10rem;
            margin-right: 10px;
            font-size: 16px;
            cursor: pointer;
        }

        .btn-refresh,
        .btn-export {
            padding: 2px 12px;
            font-size: 16px;
            margin: 0 12px 0 0;
        }


    }

    .table-wrapper {
        padding: 20px;
        table-layout: auto;

        .table-container {
            overflow: auto;
            max-height: 66vh;

            .task-table {
                border-collapse: collapse;
                width: 100%;
                margin-bottom: 20px;
                direction: rtl;
                font-size: 1rem;
                overflow: scroll;

                th,
                td {
                    text-align: right;
                    border: 1px solid #ddd;
                    padding: 10px;
                    border-bottom: 1px solid #ddd;
                }

                th {
                    background-color: #f2f2f2;
                }

                tr:nth-child(even) {
                    background-color: #f2f2f2;
                }

                tr:hover {
                    background-color: #ddd;
                }

                td:nth-child(1),
                td:nth-child(2),
                td:nth-child(3) {
                    width: 1%;
                    color: #5C5C5C;
                    cursor: pointer;
                }

                td:nth-child(5),
                td:nth-child(6),
                td:nth-child(7) {
                    width: 3%;
                    text-align: center;
                }

                td:nth-child(8),
                td:nth-child(9) {
                    width: 10%;
                }

                td:nth-child(12) {
                    width: 11%;
                }

                td:nth-child(13) {
                    width: 8%;
                }

                .btn-edit {
                    cursor: pointer;
                    font-size: 16px;
                    border-radius: 10px;
                    padding: 3px 16px;
                    font-weight: 400;
                    letter-spacing: 1px;
                    background: none;
                    border: solid rgba(0, 0, 0, 0.5) 1px;
                    color: rgba(0, 0, 0, 0.5);
                }

                .positive {
                    color: #28a745;
                }

                .negative {
                    color: #dc3545;
                }
            }
        }



        .pagination {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            list-style: none;
            padding: 0;

            li {
                display: flex;
                cursor: pointer;
                transition: background-color 0.3s ease;

                a {
                    display: inline-block;
                    padding: 5px 10px;
                    border: 1px solid #ddd;
                    color: inherit;
                    text-decoration: none;
                    width: 100%;
                    /* Make the entire button area clickable */


                }

                &:hover {
                    background-color: #f2f2f2;
                }

                &.active {
                    background-color: #007bff;
                    color: white;
                    border-color: #007bff;
                    cursor: default;
                }
            }
        }
    }

}