.sackItem {
  .item-wrapper {
    display: flex;
    text-align: center;
    padding: 1px;
    flex: 1;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    background-color: rgba(244, 244, 244, 0.3);
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
  }

  .item-wrapper:hover {
    color: rgba(0, 0, 0, 1);
    font-weight: bold;
    background-color: #e5e5e5;
    background-color: rgba(22, 119, 161, 0.05);
  }

  .load-order-to-locker-btn {
    font-size: 16px;
    border-radius: 10px;
    padding: 3px 16px;
    font-weight: 400;
    letter-spacing: 1px;
    background: none;
    border: solid rgba(0, 0, 0, 0.5) 1px;
    color: rgba(0, 0, 0, 0.5);
  }

  .load-order-to-locker-btn {
    border: solid rgba(0, 0, 0, 1) 1px;
    color: rgba(0, 0, 0, 1);
  }

  .load-order-to-locker-btn:hover {
    border: solid #29a7df 1px;
    background-color: #29a7df;
    color: #fff;
  }

  .load-order-to-locker-btn:active {
    border: solid #29a7df 1px;
    background-color: #29a7df;
    color: #fff;
  }

  .locker-name {
    background: rgb(41 167 223 / 20%);
  }

  .clickable {
    cursor: pointer;
  }

  .order-na {
    color: rgba(0, 0, 0, 0.2);
  }

  .orders-count {
    display: inline-block;
    min-width: 22px;
    min-height: 20px;
    padding: 6px;
    border-radius: 20px;
    font-weight: bold;
    color: #555;
    background-color: #fda73a;
  }
}