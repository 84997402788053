.ucs-pdt-input {
  border: solid #8a8a8a 4px;
  border-radius: 5px;
  margin: 20px 35px;
  flex: 1;
  font-size: 25px;
}

.ucs-pdt-flex1 {
  flex: 1;
}

.whlb-wrapper.chkbx-and-label-title .fa-wheelchair {
  font-size: 26px;
  color: #05b409;
  padding: 0 0 0 5px;
}
.whlb-wrapper.chkbx-and-label-title .label {
  display: inline-block;
}
