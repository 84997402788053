
 .edit-coupon.ss-container{
     padding-top: 0;
 }
 .edit-coupon.ss-container h1{
    padding:0 0 10px 0;
}
.edit-coupon .content-loading,
.edit-coupon .content-message{
    display: block;
    flex-direction: column;
    width: 100%;
    text-align: center;
    padding: 100px 50px;
    font-size: 24px;
    font-weight: bold;
    color: #2faadf;
}

.content-message{
    color: #fda73a;
}

.edit-coupon .section-info-row {vertical-align: text-bottom;}
.edit-coupon .ss-input {margin: 0 0 0 12px;}
.edit-coupon .field-valid {
    margin: 0 0 5px 0;
    display: inline-block;
}

.edit-coupon.spinner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
    width: 60vw;
    overflow: hidden;
    animation: spinnerRotate 6s linear infinite;
}

.edit-coupon .ss-button{
    width: 180px;
    font-weight: bold;
    font-size: 16px;
}
.edit-coupon .ss-button.remove-button{
    margin: 0 30px;
    background-color: #fff;
    border: solid 2px #e41811;
    color: #e41811;
    border-radius: 5px;
    font-weight: bold;
    background-color: #e4181134;
}
.edit-coupon .is-deleted{
    background-color: #e4181154;
    border-radius: 5px;
    padding: 6px 18px;
}