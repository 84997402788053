.order-relocate-wrapper {
  padding: 20px;
  .order-details {
    font-size: 16px;
    border-radius: 10px;
    padding: 3px 16px;
    font-weight: 400;
    letter-spacing: 1px;
    background: none;
    border: solid rgba(0, 0, 0, 0.5) 1px;
    color: rgba(0, 0, 0, 0.5);
    max-width: 400px;
    margin: 20px 0;
    padding: 20px;
    .details-block {
      display: inline-block;
      min-width: 300px;
      .details-label {
        display: inline-block;
        min-width: 100px;
        padding: 0 0 0 15px;
      }
      .details-value {
        display: inline-block;
        font-size: 18px;
        color: #29a7df;
        font-weight: bold;
      }
    }
  }
  .spinner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  .form-inline {
    max-width: 360px;

    .relocate-btn {
      font-size: 24px;
      border-radius: 10px;
      padding: 3px 16px;
      font-weight: 400;
      letter-spacing: 1px;
      background-color: #29a7df;
      border: solid #29a7df 1px;
      color: #fff;
    }

    .relocate-btn:hover {
      border: solid rgba(0, 0, 0, 0.5) 1px;
      background-color: #fda73a;
      color: rgba(0, 0, 0, 0.5);
    }

    .relocate-btn:active {
      border: solid rgba(0, 0, 0, 0.5) 1px;
      background-color: #fda73a;
      color: rgba(0, 0, 0, 0.5);
    }

    .validation-message {
      padding: 12px;
      color: red;
      font-weight: bold;
    }
  }
}
