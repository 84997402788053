.ewq-table-item {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    border-bottom: solid #8a8a8a 1px;
    padding: 2px 0;
    white-space: initial;
}

.ewq-table-itm-elem {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    color: black;
    font-size: 16px;
    text-align: center;
}

.ewq-elem-flex-2 {
    flex: 2;
}

.ewq-table-elem-btn {
    font-size: 16px;
    padding: 3px 5px;
    font-weight: 600;
    flex: 1;
}

.ewq-table-itm-price {
    appearance: none;
    direction: ltr;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}