.routeAreaCodeList {

    padding: 8px 8px;

    .table-container {
        width: 80vw;
        overflow-x: auto;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;

        th,
        td {
            padding: 8px;
            border: 1px solid #ccc;
            text-align: right;
        }

        td:nth-child(4) {
            width: 14%;
        }

        td:last-child {
            text-align: center;
        }

        th {
            background-color: #f2f2f2;
            font-weight: bold;
        }

        tbody tr:hover {
            background-color: #e0e0e0;
        }
    }


}