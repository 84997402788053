.or-itm-wrapper {
    display: flex;
    flex: 1;
}

.or-itm-data {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 15px;
}

.or-itm-header {
    color: #1076ba;
    border: none;
    border-bottom: solid 1px #1076ba;
}