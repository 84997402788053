.CertificateOrdersTable {
    padding: 2px 16px;

    .table-name {
        font-size: 28px;
        text-align: center;

    }

    .print-date {
        display: none;

        div:nth-child(2) {
            direction: ltr;
            margin-right: 12px;
        }
    }

    .shipping-list {
        font-size: 16px;
        width: 100%;
        margin: 2px 0 0 0;

        table {

            border-collapse: collapse;
        }

        th,
        td {
            max-width: 300px;
            overflow-wrap: break-word;
            word-wrap: break-word;
            padding: 8px;
            text-align: center;
            border-bottom: 1px solid #ddd;
        }

        th {
            background-color: #f2f2f2;
        }

        tr:hover {
            background-color: #f5f5f5;
        }
    }

    @media print {
        .print-date {
            font-size: 12px;
            display: flex;
        }
    }
}