.sackScanner {
    display: flex;
    align-items: center;
    margin: 20px 8px 0px 8px;

    .sackScanner-wrapper {

        width: 96vw;
        text-align: center;

        h1 {
            margin: 0px 0 12px 0;
        }

        .center-text {
            margin: 12px 0 0px 0;
        }

        .center-input {
            display: flex;
            justify-content: center;
            align-items: center;

            input {
                height: 26px;
                width: 280px;
            }

            .btn-manual {
                padding: 4px 16px;
                border-color: #1ebbd7;
                font-size: 18px;
            }
        }

        .btn-back {
            padding: 4px 16px;
            border-color: #1ebbd7;
            font-size: 18px;
            margin-top: 42px;
        }
    }

    @media (min-width: 850px) {
        .sackScanner-wrapper {
            width: 20vw;
        }
    }
}