.station-lockers-history-wrapper .lockers-filterer {
  display: flex;
  margin: 18px 10px 10px 10px;
}

.station-lockers-history-wrapper .lockers-filterer .search-input {
  font-size: 16px;
  min-width: 80px;
  max-height: 30px;
  padding: 4px 12px;
  margin: 10px 12px 0px 15px;
  box-shadow: 0 0 #8a8a8a;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.station-lockers-history-wrapper .lockers-filterer .ss-group .ss-input {
  font-size: 16px;
  min-width: 140px;
  padding: 0 12px;
  margin: 10px 12px 0px 15px;
  box-shadow: 0 0 #8a8a8a;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.station-lockers-history-wrapper .lockers-filterer .search-input:hover,
.station-lockers-history-wrapper .lockers-filterer .ss-group .ss-input:hover {
  border: 1px solid #29a7df;
}

.station-lockers-history-wrapper .station-editor-spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.station-lockers-history-wrapper .locker-list {
}

.select-autocomplete-wrapper {
  padding: 10px 12px 0px 15px;
  max-width: 300px;
  min-width: 170px;
}

.select-autocomplete-wrapper.sations-list {
  min-width: 270px;
}

.base-select__control {
  border-radius: 6px;
  direction: ltr;
}
.base-select__control:hover {
  border: 1px solid #29a7df;
}
.base-select__placeholder {
  font-size: 16px;
  font-family: Heebo;
  color: #8a8a8a;
}
.base-select__value-container {
  direction: rtl;
}
.base-select__multi-value {
  background: rgba(41, 167, 223, 0.2);
  border-radius: 21px;
  padding: 2px 4px;
  direction: ltr;
}
.base-select__multi-value__label {
  opacity: 1;
}
.base-select__menu-list {
  padding: 2px 6px;
  max-height: 600px;
}
.base-select__option {
  font-size: 14px;
  border-radius: 6px;
  white-space: nowrap;
}
.base-select__option:hover {
  background: rgba(41, 167, 223, 0.2);
}

.list-counter {
  padding-right: 22px;
  font-size: 14px;
  color: #8a8a8a;
}
