.new-supplier {
  .content-loading,
  .content-message {
    display: block;
    flex-direction: column;
    width: 100%;
    text-align: center;
    padding: 100px 50px;
    font-size: 24px;
    font-weight: bold;
    color: #2faadf;
  }

  .content-message {
    color: #fda73a;
  }

  .section-info-row {
    vertical-align: text-bottom;
  }
  .ss-input {
    margin: 0 0 0 12px;
  }
  .ss-button {
    width: 180px;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
  }
  .field-valid {
    margin: 0 0 5px 0;
    display: inline-block;
  }

  .spinner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
    width: 60vw;
    overflow: hidden;
    animation: spinnerRotate 6s linear infinite;
  }
}
