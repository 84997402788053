* {
    margin: 0;
    padding: 0;
    font-family: Heebo;
}

/*  
    COLORS - #2faadf #595358 #022B3A #eff1f3 #1076ba  - 
    https://coolors.co/2faadf-595358-182b4a-eff1f3-1076ba
     #FFF #8EB8E5 #70A9A1 #40798C #190B28 
*/

body {
    /** ERASE THAT ONCE MORE THAN ONE LANG SHOULD BE SUPPORTED */
    direction: rtl;
}

:root {
    /* FACE-BOOK PALLETE */
    /* --main-pallete-lighter: white;
    --main-pallete-light: #f7f7f7;
    --main-pallete-normal: #dfe3ee;
    --main-pallete-dark: #8b9dc3;
    --main-pallete-darker: #3b5998; */
    /* OLD PALLETE */
    --main-pallete-lighter: #FFF;
    --main-pallete-light: #333333;
    --main-pallete-normal: #DCDDDD;
    /* --main-pallete-dark: #FDA73A; */
    --main-pallete-dark: rgb(169, 173, 173);
    --main-pallete-darker: #2faadf;
    --pallete-grey: rgb(169, 173, 173);
    /* --main-pallete-darker: #1076ba; */
}


@font-face {
    font-family: Heebo;
    src: url(assets/fonts/Heebo-Regular.ttf);
}
@page { size: auto;  margin: 0mm; }