.stations-filterer {
  display: flex;
  margin: 18px 0 10px 0;
}

.stations-filterer .search-input {
  font-size: 16px;
  min-width: 80px;
  max-height: 30px;
  padding: 4px 12px;
  margin: 10px 12px 0px 15px;
  box-shadow: 0 0 #8a8a8a;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.stations-filterer .search-input {
  width: 230px;
}

.stations-filterer .ss-group .ss-input {
  font-size: 16px;
  min-width: 140px;
  padding: 0 12px;
  margin: 10px 12px 0px 15px;
  box-shadow: 0 0 #8a8a8a;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.stations-filterer .search-input:hover,
.stations-filterer .ss-group .ss-input:hover {
  border: 1px solid #29a7df;
}

.stations-filterer .selector{
  padding: 10px 12px 0px 12px;
  min-width: 160px;
}
