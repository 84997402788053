.title {
  display: inline-block;
}
.locker-status-note-val textarea {
  padding: 16px;
  font-size: 18px;
}

.put-order-to-locker .order-list-counter {
  padding-right: 22px;
  font-size: 14px;
  font-weight: bold;
  color: #8a8a8a;
  text-align: right;
}

.put-order-to-locker .ol-item-wrapper div {
  margin-left: 5px;
}

.put-order-to-locker .line-wrapper,
.put-order-to-locker-aproove .line-wrapper {
  display: block;
  text-align: center;
  padding: 20px 0 0 0;
}
.put-order-to-locker .line-wrapper .line,
.put-order-to-locker-aproove .line-wrapper .line {
  display: flex;
  align-self: center;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-color: #ededed;
}

.put-order-to-locker .line-wrapper .line:nth-child(2n),
.put-order-to-locker-aproove .line-wrapper .line:nth-child(2n) {
  background-color: #fdfdfd;
}

.put-order-to-locker .line-wrapper .line .label,
.put-order-to-locker-aproove .line-wrapper .line .label {
  display: flex;
  color: #8a8a8a;
  font-weight: normal;
  font-size: 22px;
  width: 150px;
}
.put-order-to-locker .line-wrapper .line .value,
.put-order-to-locker-aproove .line-wrapper .line .value {
  display: flex;
  font-size: 22px;
  width: 250px;
}
