.ss-file-input[type="file"] {
    position: absolute;
    border: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip-path: rect(1px, 1px, 1px, 1px);
    opacity: 0;
    z-index: -1;
}

.ss-file-input[type="file"]+label {
    display: inline-block;
    background-color: #1076ba;
    padding: 7px 15px;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: inherit;
    font-family: inherit;
    text-align: center;
    cursor: pointer;
}

.ss-file-input[type="file"]:focus+label,
.ss-file-input[type="file"]+label:hover {
    /* background-color: #f15d22; */
}

.ss-file-input[type="file"]:focus+label {
    outline: 1px dotted #000;
    /* outline: thin dotted; */
}

/* think of it */
input.visually-hidden:focus-within+label {
    outline: thin dotted;
}
