.stations-section-locker-block {
  .select-wrapper {
    width: 130px;
    display: inline-block;
    color: #4c4c4c;
    font-size: 1rem;
    min-width: 130px;
    position: relative;

    .select-label {
      font-size: 16px;
    }
  }

  .ss-input {
    padding: 7px 0;
    border-color: hsl(0, 0%, 80%);
    border: 0;
    text-align: center;
    border-radius: 20px;
    font-weight: bold;
    background: rgb(41 167 223 / 20%);
  }

  .chkbx-and-upper-label-title {
    color: #4c4c4c;
  }

  .fa-wheelchair {
    font-size: 26px;
    color: #05b409;
    padding: 0 0 0 5px;
  }
}
