.searchUser-wrapper {
    z-index: 12;

    .overlay {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(245, 245, 245, 0.4);
        z-index: 6;

        .searchUser-container {
            position: relative;
            display: flex;
            top: 25%;
            justify-content: center;
        }

        .searchUser {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 540px;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 5px 3px 27px rgba(63, 110, 131, 0.2509803922);
            padding: 0px 0px 16px 0px;

            .search-user-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                padding: 12px 0 0 0;

                .phone-input {
                    padding: 10px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    font-size: 18px;
                    margin: 12px 0 24px 0;

                }

                .message-text {
                    font-size: 16px;
                    font-weight: bold;
                    margin-left: 12px;
                }

                .user-data {
                    font-size: 22px;
                    font-weight: bold;
                    margin: 0 0 20px 0;
                }

                .btn-holder {

                    .btn-yes {
                        padding: 10px 0;
                        border: none;
                        border-radius: 6px;
                        background-color: #29a7df;
                        color: #fff;
                        letter-spacing: 0px;
                        font-size: 16px;
                        line-height: 24px;
                        min-width: 92px;
                        cursor: pointer;
                        margin-left: 11px;
                    }

                    .btn-no {
                        font-size: 16px;
                        padding: 10px 22px;
                        line-height: 24px;
                        cursor: pointer;
                    }

                    .btn-disabled {
                        margin-left: 11px;
                        padding: 10px 0;
                        border: none;
                        border-radius: 6px;
                        font-size: 16px;
                        line-height: 24px;
                        min-width: 92px;
                        border: 1px solid #999999;
                        background-color: #cccccc;
                        color: #666666;
                        cursor: not-allowed;
                    }
                }
            }



        }
    }
}