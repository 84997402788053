#supplier-section-container {
  background-color: #eee;
  padding: 20px;

  .section-body {
    background-color: white;
    border: 0.3px solid #c4c4c4;
    padding: 20px;
  }

  .section-menu {
    padding: 15px 0;
    background-color: white;
    border: 0.3px solid #c4c4c4;
    margin: 0 0 20px 0;
  }

  .section-menu-item {
    display: inline-block;
    margin: 0 20px;
  }

  .section-menu-item a {
    text-decoration: none;
    padding: 7px;
  }

  .section-header {
    display: inline-block;
    margin: 0 0 20px 20px;
  }

  .color-wrap {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    display: inline-block;
    box-shadow: 3px 3px 9px #3f6e8355;
  }
}

.ss-label {
  display: block;
  color: #4c4c4c;
  font-size: 1rem;
}

.ss-input {
  border: 1px solid gray;
  border-radius: 3px;
  font-size: 1rem;
  margin: 0 0 0 20px;
  padding: 3px;
}

.ss-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 20px;
}

.ss-line {
  display: flex;
  justify-content: space-between;
}

.ss-group {
  display: inline-block;
  margin: 5px 0 5px 0;
}

.ss-button {
  font-family: inherit;
  border: 0;
  border-radius: 5px;
  padding: 5px;
  margin: 10px 0;
  width: 100px;
  background-color: #1076ba;
}

.ols-wrapper {
  overflow-y: auto;
  max-height: calc(100vh - 50px);
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ols-wrapper-list {
  font-size: 14px;
  text-align: center;
}

.ols-slct-input {
  border: solid #8a8a8a 2px;
  border-radius: 5px;
  margin: 10px 20px;
  font-size: 16px;
  max-width: 200px;
  align-self: center;
}

.ols-btn-wrapper {
  display: flex;
  justify-content: space-between;
  width: 800px;
}

.ols-btn {
  font-size: 14px;
  width: 40px;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  background-color: var(--main-pallete-darker);
}

.ols-wrapper::-webkit-scrollbar {
  width: 8px;
}

.ols-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #e2e2e2;
}

.ols-wrapper::-webkit-scrollbar-thumb {
  background: #333333;
  border-radius: 4px;
}

.ols-slct-filterer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ols-slct-filterer-muls {
  max-width: 350px;
}

.cror-modal-inputs-wrapper .content-loading,
.cror-modal-inputs-wrapper .content-message {
  display: block;
  flex-direction: column;
  width: 100%;
  text-align: center;
  padding: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #2faadf;
}

.content-message {
  color: #fda73a;
}
