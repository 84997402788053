.new-city {
    padding: 0px 20px;

    .city-name-selector {
        width: 342px;
        font-size: 1rem;
        color: #4c4c4c;
    }

    .district-selector {
        margin: 8px 0 0 0;
        width: 342px;
        font-size: 1rem;
        color: #4c4c4c;
    }
}