.export-reserved-lockers {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  max-width: 400px;
  margin: 0 auto;

  h3 {
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
    width: 100%;
  }

  .reserved-lockers-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .station-selector {
      margin: 8px 0px;
    }

    .input-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;

      label {
        margin-bottom: 5px;
        font-size: 16px;
      }

      input[type="date"] {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
      }
    }

    .error-message {
      color: red;
      margin-bottom: 15px;
      font-size: 14px;
      font-weight: bold;
    }


  }
}