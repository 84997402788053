.delivery-sack-menu {
  padding: 10px 14px 0 0;

  .sacks-title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  .delivery_menu_holder {
    margin: 0px 10px 16px 0;

    .btn_delivery_holder {
      display: flex;
      flex-direction: column;
      align-items: center;

      .btn_delivery {
        text-align: center;
        font-family: Heebo;
        border-radius: 23px;
        cursor: pointer;
        font-size: 16px;
        padding: 6px 0px;
        width: 128px;
        background: #fff;
        color: #0d7fb1;
        border: 2px solid #0d7fb1;

        &:hover {
          border: 2px solid #29a7df;
          background: #29a7df;
          color: #fff;
        }
      }

      .sacks {
        color: #0d7fb1;
        min-height: 127px;
      }
    }
  }
}

@media (max-width: 650px) {
  .delivery-sack-menu {
    display: flex;
    justify-content: center;
  }
}