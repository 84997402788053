.record-table.user-subscriptions-list {
  color: #000;
  //width: 930px;
  width: 1280px;
  height: 306px;
  overflow-y: auto;
  font-size: 16px;
  margin-top: 20px;
  .record-row {
    display: block;
    border-top: dotted 2px #ddd9d9;
  }

  .record-row:nth-child(even) {
    background-color: #f2f2f2;
  }
  .record-col {
    display: inline-block;
    padding: 0 0 0 8px;

    width: 148px;
    vertical-align: middle;
    border-left: dotted 2px #ddd;
  }
  .record-col.icon {
    width: 22px;
  }
  .record-col.subscribtion-name {
    width: 80px;
  }
  .record-col.order-type {
    width: 90px;
    padding: 5px 20px;
  }

  .record-col.start-date {
    width: 90px;
    padding: 5px 20px;
  }

  .record-col.day-in-month {
    width: 80px;
    padding: 3px 20px;
  }

  .record-col.amount {
    width: 25px;
    padding: 3px 20px;
  }
  .record-col.user-fullname {
    width: 140px;
  }
  .record-col.station-info {
    width: 140px;
  }
  .record-col.delete {
    width: 90px;
    padding: 3px 20px;
  }
  .record-col.delete.red {
    color: #d82607;
  }
  .record-col.delete.active {
    cursor: pointer;
  }
  .fa-check-circle {
    color: rgb(51, 172, 67);
    font-size: 21px;
  }
  .fa.fa-times-circle,
  .fas.fa-trash-alt {
    color: rgb(216, 38, 7);
    font-size: 21px;
  }
}
