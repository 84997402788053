.order-number{
    display: inline-block;
    font-size: 24px;
    padding-right: 12px;
}
.title{ display: inline-block;}

.cror-modal-inputs-wrapper{
    padding: 20px 20px 0 20px;
}

.items-original-table {
    margin-top: 14px;
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }
  
    td,
    th {
      border: 1px solid #dddddd;
      text-align: right;
      padding: 8px;
      width: 150px;
    }
    td{
        font-size: 16px;
        font-weight: 100;
    }
  }