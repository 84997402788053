.stationSacksScreen {
    padding: 20px;
    font-family: Arial, sans-serif;

    h1 {
        text-align: center;
        color: #333;
        margin-bottom: 20px;
        font-size: 1.8em;
    }

    .filters {
        display: flex;
        gap: 15px;
        margin-bottom: 20px;

        .select-route,
        .select-station {
            font-size: 1.2em;
            width: 250px;
        }
    }

    .station-sacks-table {
        width: 100%;
        border-collapse: collapse;
        margin: 0 auto;

        thead {
            background-color: #f4f4f4;

            th {
                padding: 10px;
                border: 1px solid #ddd;
                text-align: right;
                font-weight: bold;
                font-size: 1.2em;
                color: #555;
            }
        }

        tbody {
            tr {
                &:nth-child(even) {
                    background-color: #f9f9f9;
                }

                &:hover {
                    background-color: #e9f5ff;
                }
            }

            td {
                padding: 10px;
                border: 1px solid #ddd;
                text-align: right;
                font-size: 1.2em;
                color: #333;
            }
        }
    }
}