.city-section-container {
  font-family: Roboto;
  font-size: 0.8rem;

  .city-section-body {
    background-color: white;
  }

  .city-section-menu {
    font-size: 1rem;
  }

  nav {
    width: 100%;

    ul {
      list-style: none;
      padding: 0 10px 0 0;
      margin: 0;
      display: flex;
      background-color: #f0f0f9;
      border-bottom: 1px solid #ccc;

      li {
        margin: 0;
        padding: 10px;

        a {
          text-decoration: none;
          color: #333;
          font-weight: bold;
          font-size: 16px;

          &:hover {
            color: #007bff;
          }
        }
      }
    }
  }

  tr:hover {
    background: #eee;
  }

  .station-editor-spinner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
  }
}