.designed-list-wrapper {
    overflow-y: auto;
    max-height: 80vh;
    /* CHANGING THE SCROLL BAR POSITION - LEFT => RIGHT */
    flex: 1;
    direction: ltr;
}

.designed-list-wrapper::-webkit-scrollbar {
    width: 8px;
}

.designed-list-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: #e2e2e2;
}

.designed-list-wrapper::-webkit-scrollbar-thumb {
    background: #333333;
    border-radius: 4px;
}