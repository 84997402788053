.error-wrapper{
    display: inline-block;
    position: relative;
    border: solid 1px red;
    background-color: #fff;
    border-radius: 5px;
}
.error-text{
    display: inline-block;
    padding: 7px 15px;
    
    color:red;
    font-weight: bold;
}
.error-img{
    display: inline-block;
    position: relative;
    width: 30px;
    color: red;
    height: 20px;
    cursor: pointer;
    
    
}
.error-img div{
    position: absolute;
    font-size: 2rem;
    transform: rotate(45deg);
    right: 6px;
    top:-7px;
}