.order-slct-menu-wrapper {
    display: flex;
    flex-direction: column;
}

.order-slct-menu-header {
    display: flex;
    flex-direction: column;
    border-bottom: #8a8a8a3a solid 1px;
}

.order-slct-btn {
    font-size: 18px;
    font-weight: 700;
    padding: 10px 15px;
    margin: 10px 20px;
}

.order-slct-input {
    border: solid #8a8a8a 2px;
    border-radius: 5px;
    margin: 10px 20px;
    /* flex: 1; */
    font-size: 16px;
}