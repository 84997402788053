.driver-page {
  @media print {
    .btn-print {
      display: none;
    }
  }

  padding: 24px;
  margin-bottom: 120px;

  .title {
    font-weight: bold;
  }

  .inputs-wrapper {
    display: flex;
    align-items: center;
    margin: 10px 0 20px 0;
  }

  .select-routs {
    width: 324px;
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    max-width: 2480px;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: right;
    padding: 8px;
    width: 180px;
    margin-bottom: 40px;
  }

  .orders-count {
    line-height: 24px;
    direction: ltr;
  }

  .blbtn-wrapper {
    width: 120px;
    font-size: 23px;
    padding: 2px 0;
    margin: 0px 22px 0px 0px;
  }
}