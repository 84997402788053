.supplierPrices {

   .table-holder-supplier-prices {
      max-height: 50vh;
   }

   .table-title {
      margin: 12px 0 12px 0;
   }

   .table-holder-supplier-prices {
      overflow-y: scroll;
      height: 700px;
      display: block;

   }

   .select-supplier-holder {
      max-width: 300px;
   }

   .highlighted-table-editable-text-area {
      background-color: #fff;
   }

   #supplier-prices-table {
      table-layout: fixed;
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;


      tbody tr {
         border-bottom: 1px solid gray;
      }

      th {
         background: #eee;
         box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
         position: sticky;
         top: 0;
         font-weight: 700;
         text-align: start;
         padding: 5px;
         text-align: center;
      }

      td {
         padding: 5px;
         text-align: center;
      }

      th:nth-child(1) {
         text-align: start;
      }

      td:nth-child(1) {
         text-align: start;
      }

      .priceHolder {
         display: flex;
         align-items: center;
         margin-left: 8px;
      }

      .table-editable-text-area {
         width: 40px;
      }

      .btn-update-price {
         padding: 8px 16px;
         border-radius: 6px;
         border: none;
         font-size: 18px;
         cursor: pointer;
      }

      .btn-update-price:hover {
         background-color: #29a7df;
      }
   }
}

@media only screen and (max-width: 1600px) {
   .supplierPrices {
      .table-holder-supplier-prices {
         max-height: 36vh;
      }
   }
}