.orsum-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 15px 0 0 0;
}

.orsum-title-value-wrapper {
    display: flex;
    margin: 0 15px;
}

.orsum-blue {
    color: #29a7df;
    font-weight: 600;
    font-size: 18px;
}

.orsum-blue2 {
    font-weight: 900;
}