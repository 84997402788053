.drawer-item-wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--main-pallete-light);
    font-size: 30px;
    transition: all .5s cubic-bezier(.46,.26,.35,1.04), width .3s cubic-bezier(.46,.26,.35,1.04);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px 0;
    padding: 10px 0;
}

.drawer-item-wrapper:hover {
    color: var(--main-pallete-dark);
    background-color: var(--main-pallete-lighter);
}

.drawer-item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin: 0 6px;
}

.drawer-item-text {
    flex: 4;
    font-size: 16px;
}


  @media (max-width: 1600px) {
    .drawer-item-wrapper {
        padding: 4px 0;
    }
  }