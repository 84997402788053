.order-number{
    display: inline-block;
    font-size: 24px;
    padding-right: 12px;
}
.title{ display: inline-block;}

.order-details{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6rem; 
}