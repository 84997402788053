.delivery-sack {
  .title {
    display: flex;
    justify-content: center;
    margin: 16px 0 0 10px;
  }
  .logo {
    margin: -30px 22px 0px 0;
  }
  .list-count {
    margin: 12px 22px 2px 0px;
    color: #8a8a8e;
    font-weight: bold;
  }
  .orders-count {
    display: inline-block;
    min-width: 22px;
    min-height: 20px;
    padding: 4px;
    border-radius: 20px;
    font-weight: bold;
    color: #555;
    background-color: #fda73a;
    text-align: center;
    margin: 0 6px;
  }
  .list-title {
    padding: 0 10px;
    display: inline;
    color: #29a7df;
    font-weight: bold;
    font-size: 22px;
  }
  input {
    height: 36px;
    background: #ffffff;
    border: 1px solid #afafaf;
    box-sizing: border-box;
    border-radius: 6px;
    color: #000000;
    font-size: 16px;
    line-height: 23px;
    padding-right: 14px;
    margin: 0 22px 0 0px;
    &:focus {
      outline: none !important;
      border: 1px solid #3bafe1;
    }
  }
  .btn_finish_holder {
    display: flex;
    justify-content: flex-end;
    margin: 35px 0px 0px 14px;
    .btn-finish {
      padding: 6px 40px;
    }
  }

  .select-sack-btn-wrapper {
    display: inline-block;
    margin: 0 10px 10px 10px;
  }
  .btn-table {
    width: auto;
    padding: 4px 16px;
  }
}
