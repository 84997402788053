.designed-item-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 250px;
  padding: 10px 25px;
  background: white;
  border-bottom: #8a8a8a3a solid 1px;
  direction: rtl;
}

.designed-item-wrapper:hover {
  background: #e7d5d5;
  cursor: pointer;
}

.designed-item-selected-wrapper {
  background: #f5f5f5;
}

.designed-item-containter {
  display: flex;
  flex-direction: column;
}

.designed-item-img {
  width: 35px;
  height: 35px;
}

.designed-item-selected-title {
  color: #29a7df;
}
