.pagin-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: red; */
    /* color:black; */
    margin: 10px 50px;
}

.pagin-btn {
    flex: 1;
    /* margin: 0 40px; */
    font-size: 18px;
    font-weight: 600;
    max-width: 60px;
}

.pagin-selected {
    background: #1076ba;
}