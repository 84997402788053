.forms-wrapper {
  display: flex;
  .form-wrapper {
    display: flexbox;
    border: 0;
    border-left: solid 1px #c4c4c4;
    margin-left: 36px;
    padding-left: 18px;
  }
}
