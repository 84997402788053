.FormGroup {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0 0 15px 0;
}

.Label {
    min-width: 80px;
}

.DatePicker {
    padding: 5px;

}