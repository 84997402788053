.supplier-edit-btn {
  font-size: 16px;
  border-radius: 10px;
  padding: 3px 16px;
  font-weight: 400;
  letter-spacing: 1px;
  background: none;
  border: solid rgba(0, 0, 0, 0.5) 1px;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.ol-item-wrapper:hover .supplier-edit-btn {
  border: solid rgba(0, 0, 0, 1) 1px;
  color: rgba(0, 0, 0, 1);
}

.ol-item-wrapper:hover .supplier-edit-btn:hover {
  border: solid #29a7df 1px;
  background-color: #29a7df;
  color: #fff;
}
.ol-item-wrapper:hover .supplier-edit-btn:active {
  border: solid #29a7df 1px;
  background-color: #29a7df;
  color: #fff;
}
