.chkbx-and-label-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.chkbx-and-label-title {
    margin: 0 5px;
}

.chkbx-and-label-checkbox {
    width: 25px;
    height: 25px;
}