.whlb-wrapper {
    color: black;
    font-family: Heebo;
    font-size: 16px;
    font-weight: 500;
    /* user-select: none; */
}

.whlb-active-effect:active {
    color: #fda73a;
}

.whlb-bold {
    font-weight: 1000;
    color: #333333;
    font-size: 18px;
}