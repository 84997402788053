.wdtadj-wrapper {
    background: #e1e4e7;
    width: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wdtadj-img {
    width: 10px;
    height: 10px;
}