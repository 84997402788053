.ewq-table-headers {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    border-bottom: solid #8a8a8a 2px;
}

.ewq-table-header-elem {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    color: black;
    font-weight: 700;
    /* padding: 5px 0; */
}

.ewq-header-elem-flex-2 {
    flex: 2;
}

.ewq-header-elem-flex-w {
    flex: 4;
}

.ewq-table-header-elem-btn {
    opacity: 0;
    cursor: default;
    flex: 1;
}