.israel-post-modal {
    .modal-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 2rem 10rem 0rem 10rem;
    }

    .input-wrapper {
        display: flex;
        margin-bottom: 14px;
    }

    .station-selector {
        min-width: 242px;
    }

    .validation-msg {
        text-align: center;
        color: red;
        font-weight: bold;
    }
}