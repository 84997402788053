.cheapsim-modal {
    .modal-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 2rem 10rem 0rem 10rem;
    }

    .modal-title {
        font-size: 22px;
        font-weight: bolder;
        margin: 0px 0 26px 0;
        text-align: center;
    }

    .input-wrapper {
        display: flex;
        margin-bottom: 14px;
        justify-content: space-evenly;
        align-items: center;

        .input-text {
            font-size: 19px;
            font-weight: bold;
        }
    }

    .validation-msg {
        text-align: center;
        color: red;
        font-weight: bold;
        padding-top: 8px;
    }
}