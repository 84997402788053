.bags-popup-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;

    .bags-popup {
        position: fixed;
        z-index: 3;
        top: 18%;
        left: 40%;
        background-color: white;
        padding: 12px;
        font-size: 22px;
        width: 400px;
        height: 200px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        box-shadow: -1px 6px 18px -7px rgb(0 0 0 / 75%);

        .bags-counter {
            display: flex;
            justify-content: center;

            .minus,
            .plus {
                cursor: pointer;
                width: 20px;
                display: flex;
                height: 20px;
                background: #f2f2f2;
                border-radius: 4px;
                padding: 8px;
                border: 1px solid #ddd;
                align-items: center;
                justify-content: center;
                user-select: none;
            }

            .input-bags {
                height: 36px;
                width: 100px;
                text-align: center;
                font-size: 26px;
                border: 1px solid #ddd;
                border-radius: 4px;
                margin: 0 6px;
            }
        }





        .btn-confirm {
            display: flex;
            justify-content: center;
            margin-bottom: 8px;

            .blbtn-wrapper {
                font-size: 24px;
                padding: 2px 8px;
            }
        }
    }
}