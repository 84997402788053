.cror-modal-wrap {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: none;
  border-radius: 0;
  background: rgba(244, 245, 247, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.cror-modal-container {
  display: flex;
  flex-direction: column;
  border: solid 3px white;
  background: white;
}

.cror-modal-title {
  font-size: 30px;
  color: #1076ba;
  font-weight: 600;
}

.cror-modal-inputs-wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 70px;
  font-size: 20px;
  font-weight: 800;
  text-align: center;
}

.cror-modal-button {
  padding: 2px 50px;
}

.cror-modal-fileds-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.cror-modal-status1 {
  display: flex;
}

.cror-modal-status {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

.cror-modal-editable {
  color: black;
  font-weight: 600;
  font-size: 16px;
  padding: 0 50px;
}

.cror-modal-sum {
  font-size: 25px;
}

.cror-modal-sel-val {
  font-size: 25px;
  min-width: 300px;
}

.cror-modal-error-msg {
  color: red;
}

 .station-selector{
min-width: 300px;
}

.cror-modal-inputs-wrapper .cror-modal-status .input-title{
  margin-left: 80px;
  }
