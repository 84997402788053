.select-autocomplete-wrapper.route-select {
  font-size: 16px;
}

.base-select__control {
  border-radius: 6px;
  direction: ltr;
}
.base-select__control:hover {
  border: 1px solid #29a7df;
}
.base-select__placeholder {
  font-size: 16px;
  font-family: Heebo;
  color: #8a8a8a;
}
.base-select__value-container {
  direction: rtl;
}
.base-select__multi-value {
  background: rgba(41, 167, 223, 0.2);
  border-radius: 21px;
  padding: 2px 4px;
  direction: ltr;
}
.base-select__multi-value__label {
  opacity: 1;
}
.base-select__menu-list {
  padding: 2px 6px;
  max-height: 600px;
}
.base-select__option {
  font-size: 14px;
  border-radius: 6px;
  white-space: nowrap;
}
.base-select__option:hover {
  background: rgba(41, 167, 223, 0.2);
}
