.WHOperator {
  h3 {
    padding: 0px 24px 6px 0;
  }

  .laundry-list {
    display: flex;
    flex-wrap: wrap;
  }
}

.supplier_holder {
  margin: 0px 10px 16px 0;

  .supplier_name {
    text-align: center;
    font-weight: bold;
    padding: 0 14px;
  }

  .btn_supplier_holder {
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn_supplier {
      text-align: center;
      font-family: Heebo;
      border-radius: 23px;
      cursor: pointer;
      font-size: 16px;
      padding: 34px 0px;
      width: 128px;
    }

    .send {
      background: #0d7fb1;
      color: #ffffff;

      &:hover {
        background-color: #29a7df;
      }
    }

    .recive {
      background: #fff;
      color: #0d7fb1;
      border: 2px solid #0d7fb1;

      &:hover {
        border: 2px solid #29a7df;
        background: #29a7df;
        color: #fff;
      }
    }
  }
}

@media (max-width: 650px) {
  .laundry-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }


}