:root {
    --s-loader-wrapper-bg-color: rgb(21, 22, 43);
    --s-loader-width: 110px;
    --s-loader-height: var(--s-loader-width);
    /* --s-loader-border-color-trans: rgba(29, 161, 242, 0.2); */
    /* --s-loader-border-color-full: rgb(29, 161, 242); */
    --s-loader-border-color-trans: #1076ba;
    --s-loader-border-color-full: #2faadf;
}

.spinning-loader-circle-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinning-loader-circle {
    display: flex;
    width: 150px;
    height: 150px;
    /* width:  10.555rem; */
    /* height: 10.555rem; */
    /* align-items: flex-start; */
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: white;
    animation: circle-appearance .8s ease-in-out 1 forwards;
}

.spinning-loader {
    width: var(--s-loader-width);
    height: var(--s-loader-height);
    border: 8px solid var(--s-loader-border-color-trans);
    border-left-color: var(--s-loader-border-color-full);
    border-radius: 50%;
    background: transparent;
    animation-name: rotate-s-loader;
    animation-iteration-count: infinite;
    animation-duration: 1s;
    animation-timing-function: linear;
    position: relative;
}

@keyframes rotate-s-loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}