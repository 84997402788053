$primary-color: #3498db;
$secondary-color: #2c3e50;
$background-color: #f9f9f9;
$border-radius: 8px;

.orderInLockerExtendModal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 6;

    &.open {
        display: block;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    .modal-content {
        background-color: $background-color;
        border-radius: $border-radius;
        padding: 2rem;
        max-width: 400px;
        margin: 2rem auto;
        text-align: center;

        h2 {
            color: $secondary-color;
            margin-bottom: 1.5rem;
            font-size: 1.5rem;
        }

        .react-datepicker-wrapper {
            width: 100%;
            margin-bottom: 1rem;

            .react-datepicker__input-container {
                input {
                    width: 100%;
                    padding: 0.75rem;
                    border: 1px solid lighten($secondary-color, 40%);
                    border-radius: $border-radius;
                    font-size: 1rem;
                    max-width: 300px;

                    &:focus {
                        outline: none;
                        border-color: $primary-color;
                    }
                }
            }
        }

        .chkbx-and-label-wrapper {
            margin: 0 18px 20px 0;

        }

        .btn-holder {
            display: flex;
            justify-content: space-between;
            gap: 1rem;

            button {
                flex: 1;
                padding: 0.75rem 1.5rem;
                background-color: $primary-color;
                color: white;
                border: none;
                border-radius: $border-radius;
                font-size: 1rem;
                cursor: pointer;

                &:hover {
                    background-color: darken($primary-color, 10%);
                }

                &:focus {
                    outline: none;
                    box-shadow: 0 0 0 3px rgba($primary-color, 0.3);
                }
            }
        }
    }


    .react-datepicker-wrapper {
        input {
            margin-bottom: 12px;
            font-size: 18px;
        }
    }
}