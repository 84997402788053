.search-user-filterer {
  display: block;
  margin: 18px 0 10px 0;
}
.search-user-filterer .search-input,
.search-user-filterer .date-picker-input {
  font-size: 16px;
  min-width: 80px;
  max-height: 30px;
  padding: 4px 12px;
  margin: 10px 12px 0px 15px;
  box-shadow: 0 0 #8a8a8a;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.search-user-filterer .search-input {
  width: 230px;
}

.search-user-filterer .ss-group .ss-input {
  font-size: 16px;
  min-width: 140px;
  padding: 0 12px;
  margin: 10px 12px 0px 15px;
  box-shadow: 0 0 #8a8a8a;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.search-user-filterer .search-input:hover,
.search-user-filterer .date-picker-input:hover,
.search-user-filterer .date-picker-input:focus,
.search-user-filterer .ss-group .ss-input:hover {
  border: 1px solid #29a7df;
}

.search-user-filterer .mul-selvalpassive-itm-content {
  font-size: 16px;
  text-align: right;
  padding: 0 6px;
  align-self: center;
  flex: 1;
}

.search-user-filterer .line-wrapper {
  display: flex;
  width: 100%;
}

.search-user-filterer .label {
  min-width: 65px;
  padding-right: 6px;
  padding-top: 16px;
  align-self: start;
  text-align: left;
  color: #8a8a8a;
}

.search-user-filterer .label .clear-btn {
  cursor: pointer;
}

.select-wrapper {
  padding: 10px 12px 0px 15px;
  max-width: 400px;
  min-width: 200px;
}

.select-wrapper .autocomplete-select__control {
  direction: ltr;
  border-radius: 6px;
  box-shadow: 0 0 #8a8a8a;
}

.select-wrapper .autocomplete-select__control:hover {
  border: 1px solid #29a7df;
}

.select-wrapper .autocomplete-select__value-container {
  direction: rtl;
}

.multi-autocomplete-wrapper .autocomplete-select__placeholder {
  font-size: 16px;
  font-family: Heebo;
}

.select-wrapper .autocomplete-select__single-value {
  font-size: 16px;
  color: #8a8a8a;
}

.select-wrapper .autocomplete-select__menu-list {
  padding: 2px 6px;
}

.select-wrapper .autocomplete-select__option {
  font-size: 12px;
  border-radius: 6px;
}
