.scan_orders {
  .title {
    display: flex;
    justify-content: center;
    margin: 16px 0 0 10px;
  }

  .laundrys_count {
    margin: 12px 22px 2px 0px;
    color: #8a8a8e;
    font-weight: bold;
  }

  .input-orders {
    height: 36px;
    background: #ffffff;
    border: 1px solid #afafaf;
    box-sizing: border-box;
    border-radius: 6px;
    color: #000000;
    font-size: 16px;
    line-height: 23px;
    padding-right: 14px;
    margin: 0 22px 0 0px;

    &:focus {
      outline: none !important;
      border: 1px solid #3bafe1;
    }
  }

  .btn_finish_holder {
    display: flex;
    justify-content: flex-end;
    margin: 35px 0px 0px 14px;

    .btn-finish {
      padding: 6px 40px;
    }
  }

  .scaned-orders-title {
    margin: 2px 20px 8px 0;
    font-size: 18px;
    font-weight: bold;
  }
}