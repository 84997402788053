.stationTaskToggle {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .modal {
        top: 20%;
        right: 30%;
        left: 30%;
        bottom: 40px;
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
        max-width: 40rem;
        max-height: 22rem;
        overflow: hidden;

        .modal-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            .title {
                font-size: 2rem;
                text-align: center;
                margin-bottom: 32px;
            }

            .btn-holder {
                margin-top: 24px;

                button {
                    font-size: 24px;
                    padding: 2px 20px;
                }
            }

        }

    }

}