.cheapsim {
  padding: 0px 20px;

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: right;
    padding: 8px;
    width: 180px;
  }

  tr {
    cursor: pointer;
  }

  .scaned {
    background-color: lightgreen;
  }

  .btn-redirect {
    font-size: 16px;
    border-radius: 10px;
    padding: 4px 0px;
    font-weight: 400;
    letter-spacing: 1px;
    background: none;
    border: 1px solid rgba(0, 0, 0, 0.5);
    color: rgba(0, 0, 0, 0.5);
    margin: 0;
    cursor: pointer;
    width: 100px;
  }

  .btn-redirect:hover {
    border: solid #29a7df 1px;
    background-color: #29a7df;
    color: #fff;
  }

  .cheapsim-table {
    margin: 28px 0 0 0;
  }

  .updated-table {
    margin: 108px 0 0 0;
  }

  .external-num {
    max-width: 176px;
    word-wrap: break-word;
  }
}