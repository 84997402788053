.ucs-white-frame {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 120px;
  justify-content: space-around;
  padding-right: 20px;
  white-space: nowrap;
  margin: 10px 20px;
  border: solid 2px #8a8a8a;
}

.ucs-white-frame-faded-title {
  font-size: 17px;
  color: #c1bfbf;
  font-weight: 500;
}

.ucs-white-frame-user-name-wrapper {
  display: flex;
  align-items: flex-end;
  margin: 10px 0;
}

.ucs-white-frame-user-name {
  color: #29a7df;
  font-size: 35px;
  font-weight: 1000;
  line-height: 30px;
  margin: 0 0 0 5px;
}

.ucs-wrapper {
  display: flex;
  flex-direction: column;
  background: #f4f5f7;
  zoom: 0.9;
  overflow-y: auto;
  flex: 1;
}

.ucs-input {
  /* border: solid #8a8a8a 4px; */
  border-radius: 5px;
  margin: 10px 15px;
  flex: 1;
  font-size: 25px;
}

.ucs-input-border {
  border: solid #8a8a8a 4px;
}

.ucs-input-mandatory {
  border: 4px solid #b80a0ad8;
}

.ucs-title {
  font-weight: 600;
  font-size: 25px;
  text-align: center;
}

.ucs-ltr {
  direction: ltr;
}

.ucs-update-button {
  cursor: pointer;
  padding: 5px 15px;
  margin: 10px 50px;
}

.ucs-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ucs-update-err-msg {
  text-align: center;
  font-size: 35px;
  font-weight: 800;
  color: red;
}

.adt-inner-wrapper .chkbx-and-label-wrapper {
  margin: 50px 78px 0px 0px;
  align-items: center;
}

.whlb-wrapper.chkbx-and-label-title {
  font-size: 18px;
  font-weight: bold;
  margin-right: 16px;
  min-width: 160px;
}
