.selcouponvalpassive-wrapper {
    display: flex;
    flex: 1;
    font-size: 18px;
    font-family: Heebo;
    font-weight: 500;
    flex-direction: column;
    user-select: none;
    /* border: 1px solid #8a8a8a; */
    border-radius: 10px;
    margin: 0 4px;
    text-align: center;
}

.selcouponvalpassive-displayed-itm {
    display: flex;
    background: white;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    border-radius: 10px;
    min-height: 40px;
}



.selcouponvalpassive-arrow-img {
    display: flex;
    width: 15px;
    height: 15px;
    align-self: center;
    justify-content: center;
}

.selcouponvalpassive-displayed-itm:hover {
    font-weight: 800;
}

.selcouponvalpassive-itms-list-wrapper {
    position: relative;
    flex: 1;
}

.selcouponvalpassive-itms-list {
    position: absolute;
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow: auto;
    width: 100%;
}

.selvalselcouponvalpassivepassive-itm {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin: 0 15px;
}

.selcouponvalpassive-itm-extra {
    background: white;
    border: 1px solid #8a8a8a;
}

.selcouponvalpassive-itm-extra:hover {
    background: #1076ba;
    color: white;
    font-weight: 600;
}

.selcouponvalpassive-picker {
    color: white;
    background: #1076ba;
    border: 0px solid black;
    font-weight: 500;
}

.selcouponvalpassive-itms-list::-webkit-scrollbar {
    width: 12px;
}

.selcouponvalpassive-itms-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: white;
}

.selcouponvalpassive-itms-list::-webkit-scrollbar-thumb {
    background: #8a8a8a;
    border-radius: 10px;
}
.selcouponvalpassive-wrapper .coupon-name{
    display: inline-block;
    width: 60%;
    text-align: center;
}
.selcouponvalpassive-wrapper .coupon-value{
    display: inline-block;
    width: 40%;
    text-align: center;
}