.oes-wrapper {
  display: flex;
  /* IMPORTANT FOR THE SLIDER..! */
  /* height: calc(100vh - 50px); */
  background: #f4f5f7;
  zoom: 0.9;
  overflow-y: auto;
  flex: 1;
}

.oes-editor-and-blur-filter-container {
  position: relative;
  display: flex;
}

.oes-editor-container-blur-with-filter {
  filter: blur(3px);
  opacity: 0.7;
}

.oes-editor-container-blur-with-filter2 {
  filter: blur(4px);
  opacity: 0.7;
}

.choose-for-edit {
  font-weight: 800;
  right: 35vw;
  top: 50vh;
  width: 300px;
  position: absolute;
}
