#imports-section-container {
    background-color: #eee;
    padding: 20px;
}

#imports-section-body {
    background-color: white;
    border: 0.3px solid #c4c4c4;
    padding: 20px;
}

#imports-section-menu {
    padding: 15px 0;
    background-color: white;
    border: 0.3px solid #c4c4c4;
    margin: 0 0 20px 0;
}

.imports-section-menu-item {
    display: inline-block;
    margin: 0 20px;
}

.imports-section-menu-item a {
    text-decoration: none;
    padding: 7px;
}

.import-section-header {
    display: inline-block;
    margin: 0 0 20px 20px;
}

.file-input-message {
    margin: 0 20px;
}

.import-status-message {
    margin: 20px 0;
}

.import-success-list,
.import-errors-list {
    margin: 20px 0;
}

.import-success-list li,
.import-errors-list li{
    list-style: square inside none;
}
