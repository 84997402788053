.order_table {
  padding: 0px 20px;
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: right;
    padding: 8px;
    width: 180px;
  }
  .scaned {
    background-color: lightgreen;
  }

  td:last-child {
  }
}

.list-spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}
