.slider-wrapper {
    display: flex;
    background: #f4f5f7;
    /* background: violet; */
}

.slider-inner-wrapper {
    display: flex;
    flex-direction: column;
    /* background: lime; */
}