.date-picker-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0;

  input {
    border: 1px solid #29a7df;
    border-radius: 6px;
  }
  .date-picker-label {
    position: absolute;
    display: block;
    top: -12px;
    right: 12px;
    font-size: 12px;
  }
  .Label {
    min-width: 80px;
  }

  .date-picker {
    padding: 5px;
  }
}
