.multi-autocomplete-wrapper {
  padding: 10px 12px 0px 15px;
  max-width: 300px;
  min-width: 170px;
}
.multi-autocomplete-wrapper.type-list {
  max-width: 220px;
  min-width: 170px;
}
.multi-autocomplete-wrapper.sations-list {
  min-width: 270px;
}
.multi-autocomplete-wrapper.user-roles-list {
  min-width: 190px;
}
.multi-autocomplete-wrapper .multi-select__control {
  border-radius: 6px;
  direction: ltr;
}
.multi-autocomplete-wrapper .multi-select__control:hover {
  border: 1px solid #29a7df;
}

.multi-autocomplete-wrapper .multi-select__placeholder {
  font-size: 16px;
  font-family: Heebo;
  color: #8a8a8a;
}

.multi-autocomplete-wrapper .multi-select__value-container {
  direction: rtl;
}

.multi-autocomplete-wrapper .multi-select__multi-value {
  background: rgba(41, 167, 223, 0.2);
  border-radius: 21px;
  padding: 2px 4px;
  direction: ltr;
}
.multi-autocomplete-wrapper .multi-select__multi-value__label {
  opacity: 1;
}
.multi-autocomplete-wrapper .multi-select__menu-list {
  padding: 2px 6px;
  max-height: 600px;
}
.multi-autocomplete-wrapper .multi-select__option {
  font-size: 14px;
  border-radius: 6px;
  white-space: nowrap;
}

.multi-autocomplete-wrapper .multi-select__option:hover {
  background: rgba(41, 167, 223, 0.2);
}
/*
<div class="multi-select-container">
  <div class="multi-select__control">
    <div class="multi-select__value-container">...</div>
    <div class="multi-select__indicators">...</div>
  </div>
  <div class="multi-select__menu">
    <div class="multi-select__menu-list">
      <div class="multi-select__option">...</div>
    </div>
  </div>
</div>
*/
