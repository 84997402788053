#stations-section-container {
  font-family: Roboto;
  font-size: 0.8rem;
}

#stations-section-body {
  position: relative;
  max-height: 86vh;
  min-height: 80vh;
  background-color: white;
  overflow-y: scroll; 
}

#stations-section-menu {
  list-style: none;
  padding: 8px 0;
  margin: 0;
  display: flex;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ccc;

}

.stations-section-menu-item {
  display: inline-block;
  margin: 0 20px;
}

.stations-section-menu-item a{
  text-decoration: none;
    color: #333;
    font-weight: bold;
    font-size: 16px;
}

.chkbx-and-label-wrapper {
  justify-content: right;
  align-items: self-start;
  margin: 3px 0;
}

#station-section-lockers-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.stations-section-locker-block {
  display: inline-block;
  margin: 10px;
  border-radius: 20px;
  background-color: #f7f7f7;
}

.ss-label {
  display: block;
  color: #4c4c4c;
  font-size: 1rem;
}

.ss-input {
  /* direction: ltr; */
  border: 1px solid gray;
  border-radius: 3px;
  font-size: 1rem;
  margin: 0 0 0 20px;
  padding: 3px;
}

.ss-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 20px;
}

.ss-line {
  display: flex;
  justify-content: space-between;
}

.ss-group {
  display: inline-block;
  margin: 5px 0 5px 0;
}

.ss-button {
  font-family: inherit;
  border: 0;
  border-radius: 5px;
  padding: 5px;
  margin: 10px 0;
  width: 100px;
  background-color: #1076ba;
}

.comma-separator {
  font-size: 22px;
  vertical-align: bottom;
  margin-left: 18px;
}

.textarea-container .ss-group textarea.ss-input {
  width: auto;
  height: auto;
}

.video-url {
  display: flex;
  align-items: center;
}

.video-url .ss-group textarea.ss-input {
  height: 48px;
  direction: ltr;
}

.video-url .ss-button {
  margin-bottom: 16px;
}

.container-select .ss-input {
  min-width: 182px;
}

.payment-version-wrapper .css-b62m3t-container {
  width: 174px;
}

#stations-list-table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

#stations-list-table tbody tr {
  border-bottom: 1px solid gray;
}

#stations-list-table td {
  padding: 5px;
}

#stations-list-table th {
  background: #eee;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  position: sticky;
  top: 0;
  font-weight: 700;
  text-align: start;
  padding: 5px;
}

tr:hover {
  background: #eee;
}

.station-editor-spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.payment-version-wrapper .css-1s2u09g-control {
  width: 185px;
  border: 1px solid gray;
}

.payment-version-label {
  display: block;
  color: #4c4c4c;
  font-size: 1rem;
}

.payment-version-wrapper .css-1pahdxg-control {
  width: 185px;
  min-width: 185px;
}

.btn-sms {
  margin-right: 10px;
}
