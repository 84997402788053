.search-orders-screen-wrapper {
  overflow-y: auto;
  max-height: calc(100vh - 50px);
  flex: 1;
  display: flex;
  flex-direction: column;
}

.search-orders-screen-wrapper .search-btn-wrapper {
  display: inline-block;
}

.search-orders-screen-wrapper .line-wrapper {
  display: flex;
}

.search-orders-screen-wrapper .search-btn-wrapper .blbtn-wrapper {
  font-size: 20px;
  min-width: 50px;
  width: 80px;
  height: auto;
  padding: 4px 12px;
  margin: 10px 12px 0px 15px;
  box-shadow: 0 0 #8a8a8a;
  font-weight: bold;
}

.search-orders-screen-wrapper .ol-list-wrapper {
  max-height: none;
}

.ols-wrapper-list {
  font-size: 14px;
  text-align: center;
}

.ols-slct-input {
  border: solid #8a8a8a 1px;
  border-radius: 5px;
  margin: 10px 20px;
  font-size: 16px;
  max-width: 200px;
  align-self: center;
}

.ols-btn-wrapper {
  display: flex;
  justify-content: space-between;
  width: 800px;
}

.ols-btn {
  font-size: 14px;
  width: 40px;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  background-color: var(--main-pallete-darker);
}

.ols-wrapper::-webkit-scrollbar {
  width: 8px;
}

.ols-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #e2e2e2;
}

.ols-wrapper::-webkit-scrollbar-thumb {
  background: #333333;
  border-radius: 4px;
}

.ols-slct-filterer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ols-slct-filterer-muls {
  width: 190px;
  border: solid 1px #eee;
  margin: 1px;
  border-radius: 10px;
}

.search-btn {
  width: 180px;
  font-size: 20px;
  height: 30px;
  display: inline-block;
}
.no-list-items-text {
  padding: 24px;
  color: #fda73a;
}

.users-list {
  display: inline-block;
  width: 300px;
  height: 300px;
  overflow: hidden;
  padding-top: 50px;
}
.users-list .spinner-wrapper {
  width: 164px;
  height: 164px;
  zoom: 1;
}
.users-list .spinner-wrapper circle {
  stroke-width: 11;
  stroke: #2faadf;
}

.search-orders-screen-wrapper .page-step-select-wrapper {
  display: flex;
  font-size: 16px;
  margin: 10px 12px 0px 15px;
  box-shadow: 0 0 #8a8a8a;
}
.search-orders-screen-wrapper .base-btn:hover {
  border: solid #29a7df 1px;
  background-color: #29a7df;
  color: #fff;
}
.search-orders-screen-wrapper .export-wrapper {
  margin: -14px 0 20px 0;
}

.search-orders-screen-wrapper .export-wrapper .base-btn {
  display: inline;
  margin: 0 12px;
}
