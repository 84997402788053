.menu {
  position: relative;
  z-index: 5;

  .drawer-toggle-btn {
    position: inherit;
    z-index: 1000;
    background-color: #31AADF;
    color: white;
    border: none;
    cursor: pointer;
    height: 50px;
    min-width: 168px;
    font-size: 20px;
    padding: 0 0 0 26px;

    @media screen and (max-width: 768px) {
      font-size: 16px;
      min-width: 158px;
      padding: 0;
      padding: 0 0 0 4rem;
    }

    i {
      padding-left: 10px;
    }
  }


  .drawer {
    right: 0;
    width: 168px;
    height: 100%;
    background-color: #F5F5F5;
    box-shadow: 0px 0 5px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    transition: transform 0.4s ease;
    z-index: 999;

    @media screen and (max-width: 768px) {
      top: 0px;
      width: 158px;
      height: auto;
    }
  }


  .drawer-open {
    transform: translateX(0);

    @media screen and (max-width: 768px) {
      transform: translateY(0);
    }
  }

  .drawer-closed {
    transform: translateX(100%);

    @media screen and (max-width: 768px) {
      transform: translateY(-100%);
    }
  }


}

.relative {
  position: relative;

  @media screen and (max-width: 768px) {
    position: absolute;
  }
}

.fixed {
  position: fixed;

  @media screen and (max-width: 768px) {
    position: absolute;
  }
}

@media print {

  .no-print {
    display: none;
  }
}