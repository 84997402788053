.notificationPopup-wrapper {
  .overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(245, 245, 245, 0.4);
    .notificationPopup-container {
      position: relative;
      display: flex;
      top: 25%;
      justify-content: center;
    }
    .notificationPopup {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      position: relative;
      width: 540px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 5px 3px 27px #3f6e8340;
      border-radius: 21px;
      padding: 12px 16px 16px 15px;
      min-height: 340px;
      .info-icon {
        color: blue;
      }
      .message-text {
        color: #1076ba;
        text-align: center;
        letter-spacing: 0px;
        color: #1076ba;
        font-size: 34px;
        line-height: 50px;
        margin-bottom: 40px;
        max-width: 380px;
      }
      .btn-holder{
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        .btn-close {
          padding: 10px 0;
          margin-bottom: 10px;
          border: none;
          border-radius: 6px;
          background-color: #29a7df;
          color: #fff;
          letter-spacing: 0px;
          font-size: 16px;
          line-height: 24px;
          min-width: 177px;
          cursor: pointer;
        }
      }
    }
  }
}
