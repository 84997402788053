.ucs-pdt-input {
  border: solid #8a8a8a 4px;
  border-radius: 5px;
  margin: 20px 35px;
  flex: 1;
  font-size: 25px;
}

.ucs-pdt-flex1 {
  flex: 1;
}

.whlb-wrapper.chkbx-and-label-title .fa-wheelchair {
  font-size: 26px;
  color: #05b409;
  padding: 0 0 0 5px;
}
.whlb-wrapper.chkbx-and-label-title .label {
  display: inline-block;
}
.mail-subscribtion.ol-edit-btn {
  width: 150px;
  height: 40px;
  font-weight: bold;

  margin: 40px 5px 0 5px;
}

.mail-subscribtion.ol-edit-btn:hover {
  border: solid #29a7df 1px;
  background-color: #29a7df;
  color: #fff;
}
.mail-subscribtion.ol-edit-btn:active {
  border: solid #29a7df 1px;
  background-color: #29a7df;
  color: #fff;
}
