.list-header-wrapper {
  display: flex;
  text-align: center;
  font-size: 16px;
  padding: 20px 0 10px 0;
  font-style: bold;
  font-weight: bolder;
  font-style: normal;
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.03);
  border-top: solid 1px rgba(0, 0, 0, 0.2);
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
}
