.item-wrapper {
    display: flex;
    text-align: center;
    padding: 8px 0;
    border-bottom: 2px solid var(--pallete-grey);
}

.item-wrapper:hover {
    color: var(--main-pallete-lighter);
    background-color: var(--main-pallete-normal);
}

.item-last {
    /* border-bottom: rebeccapurple solid 1px; */
}