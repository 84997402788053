.cror-mail-modal-wrap {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: none;
  border-radius: 0;
  background: rgba(244, 245, 247, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
}

.cror-mail-modal-wrap .order-mail-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.cror-modal-container {
  display: flex;
  flex-direction: column;
  border: solid 3px white;
  background: white;
}

.cror-modal-title {
  font-size: 30px;
  color: #1076ba;
  font-weight: 600;
}

.cror-modal-mail-inputs-wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 100px;
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  min-width: 666px;

  .cror-modal-status {
    .input-title {
      width: 186px;
      text-align: right;
    }
  }
}

.cror-mail-modal-wrap {
  .css-b62m3t-container {
    width: 240px;
  }

  .cror-modal-status {
    justify-content: unset;
    padding: 0;
    margin-bottom: 14px;
  }

  .cror-modal-flex-status {
    display: flex;
    justify-content: space-between;
  }
}

.input-wrapper {
  display: flex;
  justify-content: start;
  margin-bottom: 14px;

  .input-title {
    margin-left: 74px;
    width: 110px;
    text-align: start;
  }

  .input-text {
    width: 230px;
    height: 34px;
    font-size: 19px;
    font-weight: bold;
    padding: 0 8px;
  }
}

.cror-mail-modal-wrap .disabled {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}