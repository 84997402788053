.ol-list-wrapper.select-order {
  display: flex;
  flex-direction: column;
  height: 300px;
  overflow-y: auto;
  padding: 10px 0;

  min-width: 1250px;
}

.ol-list-wrapper.select-order::-webkit-scrollbar {
  width: 8px;
}

.ol-list-wrapper.select-order::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #e2e2e2;
}

.ol-list-wrapper.select-order::-webkit-scrollbar-thumb {
  background: #666565;
  border-radius: 4px;
}
