.select-multiple-group {
    display: inline-block;
}

.select-multiple-label {
    display: block;
    font-size: 0.9rem;
    color: #aaa;
}

.select-multiple {
    display: inline-block;
    min-width: 150px;
    margin: 0 0 0 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

.select-multiple option {
    padding: 5px;
}
.select-multiple option:hover {
    background-color: #eee;
}