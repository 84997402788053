.app-wrapper {
    display: flex;
    height: 100vh;
}

.appbar-content-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
}

@media (max-width: 650px) {
    .app-wrapper {
    display: flex;
    flex-direction: row;
    }
}