.station-sms-body {
  display: flex;
  justify-content: center;
  .station-sms-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 360px;
    .textarea {
      margin-top: 10px;
    }
    .template-container {
      display: flex;
      justify-content: space-between;
      margin: 20px 0;
      font-size: 16px;
      font-weight: bold;
      padding: 0 20px;
      .btn-template {
        background-color: #4caf50;
        color: white;
        padding: 6px 16px;
        font-size: 16px;
        border: none;
        border-radius: 12px;
      }
      .btn-template:hover {
        background-color: #3e8e41;
      }

      .btn-template:active {
        background-color: #3e8e41;
        transform: translateY(4px);
      }
    }
    .btn-holder {
      display: flex;
      justify-content: center;
      .yesno-modal-button {
        width: 100px;
      }
    }

    .error-msg {
      color: red;
      font-size: 16px;
      margin-top: 16px;
    }
  }
}
