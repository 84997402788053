.chkbx-and-label-upper-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chkbx-and-upper-label-title {
  margin: 0 5px;
}

.chkbx-and-upper-label-checkbox {
  width: 25px;
  height: 25px;
}
