.user-subscriptions {
  direction: rtl;
  .title {
    padding: 10px 0;
  }
  .subscribe-param {
    border-bottom: dashed 1px #aaa;
    direction: rtl;
    .value {
      display: inline-block;
      font-size: 16px;
      padding-right: 12px;
      width: 300px;
      text-align: right;
    }
    .label {
      display: inline-block;
      font-size: 14px;
      font-weight: bold;
      color: #aaa;
      padding-right: 12px;
      width: 150px;
      text-align: left;
      padding-top: 3px;
    }
  }
  .action-btns {
    padding: 50px 0 0 0;
  }
}
