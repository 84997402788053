.shippingCertificatePage {
    display: flex;
    flex-direction: column;
    padding: 20px 20px;

    .selects-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        h3 {
            text-align: center;
            margin: 0 0 12px 0;
        }

        .select-routs {
            width: 560px;
            padding: 8px 20px 16px 20px;
            border: 1px solid #ccc;
            background-color: #f8f8f8;

            .selects {
                display: flex;
                justify-content: space-between;
            }



            .btn-print {
                margin: 0 0 18px 0;
                padding: 6px 8px;
                font-size: 24px;
                width: 100%;
            }
        }

        .select-returns {
            padding: 8px 20px 16px 20px;
            border: 1px solid #ccc;
            background-color: #f8f8f8;

            .btn-print-returns {
                margin: 0 0 20px 0;
                padding: 6px 8px;
                font-size: 24px;
                width: 100%;
            }
        }

        .select {
            width: 260px;
        }

        .btn-show-all {
            width: 100%;
            font-size: 20px;
            padding: 5px 18px;
            margin: 0 0 8px 0;
        }

        .select-route-laundry {
            padding: 8px 20px 16px 20px;
            border: 1px solid #ccc;
            background-color: #f8f8f8;

            .btn-print-route-laundry {
                margin: 0;
                padding: 6px 8px;
                font-size: 24px;
                width: 100%;
            }



            .radio-group {
                display: flex;
                justify-content: space-evenly;
                margin: 16px 0 22px 0;

                label {
                    input[type="radio"] {
                        margin-left: 8px;
                        width: 20px;
                        height: 20px;
                        vertical-align: middle;
                    }
                }

            }



        }
    }

    .barcode-wrapper {
        text-align: center;
        margin: 20px 0 0 0;
        display: none;
    }

    .title-type-sertificate {
        font-size: 18px;
        font-weight: bold;
        margin: 8px 0 0 0;
        text-align: center;
    }

    .table-name {
        margin: 12px 12px 0 0;
    }



    .empty-list {
        text-align: center;
        margin: 12px 0 0 0;
        font-size: 20px;
    }



    @media print {
        .btn-print {
            display: none;
        }

        .selects-wrapper {
            display: none;
        }

        .barcode-wrapper {
            display: block;
        }
    }
}