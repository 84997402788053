.SacksHistory {
    padding: 20px;

    h1 {
        font-size: 24px;
        margin-bottom: 20px;
        text-align: center;
    }

    .search-sacks {
        display: flex;
        margin-bottom: 20px;
        border: 1px solid #ccc;
        padding: 12px 0px;
        background-color: #f0f0f0;

        .btn-print-wrapper {
            display: flex;
            flex-grow: 16;
            justify-content: left;
            margin-left: 24px;

            .btn-print {
                font-size: 18px;
                padding: 0 8px;
                margin: 0;
            }
        }

        .search-input {
            display: flex;
            margin-right: 20px;
            flex-grow: 1;


            .input-field {
                padding: 2px 4px;
                font-size: 14px;
                border: 1px solid #ccc;
                border-radius: 4px;
                margin-left: 8px;
            }


        }
    }

    .search-select {
        display: flex;

        .select-account {
            margin-left: 8px;
            width: 200px;
        }


    }

    .search-button {
        padding: 4px 20px;
        font-size: 16px;
        background-color: #3498db;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;
        margin: 0;

        &:hover {
            background-color: #267bb5;
        }
    }

    .sacks-found {
        border-radius: 8px;
        margin-top: 26px;

        h2 {
            font-size: 24px;
            color: #333;
            margin-bottom: 10px;
        }

        .result-table {
            width: 100%;
            border-collapse: collapse;

            th,
            td {
                border: 1px solid #ddd;
                padding: 8px;
                text-align: center;

            }

            td {
                cursor: pointer;
            }

            th {
                background-color: antiquewhite;
            }
        }
    }

    .orders-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;

        .CertificateOrdersTable {
            padding: 0;
        }
    }

    .no-data {
        color: red;
        font-size: 18px;
        font-weight: bold;
    }

    @media print {
        h1 {
            display: none;
        }

        .no-print {
            display: none;
        }
    }
}