.ul-item-wrapper {
  display: flex;
  text-align: center;
  padding: 1px;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  background-color: rgba(244, 244, 244, 0.3);
  color: rgba(0, 0, 0, 1);
}

.ul-item-wrapper:hover {
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
  background-color: #e5e5e5;
  background-color: rgba(22, 119, 161, 0.05);
}

.ul-edit-btn {
  font-size: 16px;
  border-radius: 10px;
  padding: 3px 16px;
  font-weight: 400;
  letter-spacing: 1px;
  background: none;
  border: solid rgba(0, 0, 0, 0.5) 1px;
  color: rgba(0, 0, 0, 0.5);
}

.ul-item-wrapper:hover .ul-edit-btn {
  border: solid rgba(0, 0, 0, 1) 1px;
  color: rgba(0, 0, 0, 1);
}

.ul-item-wrapper:hover .ul-edit-btn:hover {
  border: solid #29a7df 1px;
  background-color: #29a7df;
  color: #fff;
}
.ul-item-wrapper:hover .ul-edit-btn:active {
  border: solid #29a7df 1px;
  background-color: #29a7df;
  color: #fff;
}

.ul-item-wrapper .user-active {
  color: rgb(51, 172, 67);
  font-size: 21px;
}
.ul-item-wrapper .user-deleted {
  color: rgb(216, 38, 7);
  font-size: 21px;
}
.ul-item-wrapper .source-app,
.ul-item-wrapper .date-time {
  font-size: 13px;
}
.ul-item-wrapper .source-app {
  max-width: 70px;
  overflow: hidden;
  direction: ltr;
}

.ul-item-wrapper .is-mail-subscribed,
.ul-item-wrapper .is-monthly-payment {
  color: rgb(176, 177, 179);
  font-size: 19px;
}
.ul-item-wrapper .is-mail-subscribed.active {
  color: rgb(51, 172, 67);
}

.ul-item-wrapper .is-monthly-payment.active {
  div {
    background-color: #fdb65b;
    border: 1px solid #df2929e3;
    border-radius: 20px;
    color: #df2929e3;
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    padding: 8px;
  }
}
