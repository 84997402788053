.locker-to-locker-delivery-modal {
  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 6rem 0rem 6rem;
  }

  .person-ditails-wrapper {
    border: solid 1px #eee;
    margin-bottom: 16px;
    border-radius: 10px;
    background: #f5f5f5;
    padding: 8px;

    .sub-title {
      background: #eee;
      color: #333;
      margin: 0 0 6px 0;
      padding: 0 10px;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .input-wrapper {
    display: flex;
    margin-bottom: 14px;

    .input-title {
      margin-left: 4px;
      padding-right: 14px;
      width: 120px;
      text-align: start;
    }
  }

  .station-selector {
    min-width: 242px;
  }

  .validation-msg {
    text-align: center;
    color: red;
    font-weight: bold;
  }
}