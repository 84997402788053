.stations-list .list-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 700px;
  overflow-y: auto;
  padding: 10px 0;
}

.stations-list .list-wrapper::-webkit-scrollbar {
  width: 8px;
}

.stations-list .list-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #e2e2e2;
}

.stations-list .list-wrapper::-webkit-scrollbar-thumb {
  background: #666565;
  border-radius: 4px;
}
