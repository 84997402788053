.drivers-by-route {
  direction: rtl;
  font-size: 18px;
  padding: 3px 10px;
  .route-drivers,
  .external-drivers {
    margin: 30px 0;
    .list-items-wrapper {
      height: 360px;
      overflow: auto;
    }
    .list-items-wrapper::-webkit-scrollbar-track {
      background: rgba(194, 194, 194, 0.23);
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      background-clip: padding-box;
    }

    .list-items-wrapper::-webkit-scrollbar-thumb {
      background: #2faadf;
      border-radius: 11px;
    }
    .list-items-wrapper::-webkit-scrollbar {
      width: 13px;
    }
  }
  .stations-section-station-info-row {
    .route-select,
    .driver-label {
      padding: 0 12px;
    }
    .ss-label {
      padding-top: 20px;
    }
    .multi-autocomplete-wrapper {
      max-width: none;
    }
  }
  .btn-holder {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    .blbtn-wrapper {
      padding: 10px 0;
      margin-bottom: 10px;
      border: none;
      border-radius: 6px;
      background-color: #29a7df;
      color: #fff;
      letter-spacing: 0px;
      font-size: 16px;
      line-height: 24px;
      min-width: 177px;
      cursor: pointer;

      padding: 5px 20px;
    }
    .blbtn-wrapper:disabled {
      background: #87dbff;
    }
  }
  .driverRow {
    direction: rtl;
    font-size: 18px;
    padding: 3px 10px;
    .num {
      display: inline-block;
      border-radius: 20px;
      padding: 5px 5px 2px 4px;
      width: 25px;
      height: 25px;
      background: rgb(41 167 223 / 20%);
    }
    .driver-mobile,
    .driver-name {
      display: inline-block;
      padding: 5px 5px 2px 4px;
    }
  }
}
