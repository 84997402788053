.stations-list {

  .item-wrapper {
    display: flex;
    text-align: center;
    padding: 1px;
    flex: 1;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    background-color: rgba(244, 244, 244, 0.3);
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
  }

  .item-wrapper:hover {
    color: rgba(0, 0, 0, 1);
    font-weight: bold;
    background-color: #e5e5e5;
    background-color: rgba(22, 119, 161, 0.05);
  }

  .item-last {
    /* border-bottom: rebeccapurple solid 1px; */
  }

  .base-btn,
  .edit-btn {
    font-size: 16px;
    border-radius: 10px;
    padding: 3px 16px;
    font-weight: 400;
    letter-spacing: 1px;
    background: none;
    border: solid rgba(0, 0, 0, 0.5) 1px;
    color: rgba(0, 0, 0, 0.5);
  }

  .item-wrapper:hover .base-btn,
  .item-wrapper:hover .edit-btn {
    border: solid rgba(0, 0, 0, 1) 1px;
    color: rgba(0, 0, 0, 1);
  }

  .item-wrapper:hover .base-btn:hover,
  .item-wrapper:hover .edit-btn:hover {
    border: solid #29a7df 1px;
    background-color: #29a7df;
    color: #fff;
  }

  .item-wrapper:hover .base-btn:active,
  .item-wrapper:hover .edit-btn:active {
    border: solid #29a7df 1px;
    background-color: #29a7df;
    color: #fff;
  }

  .item-wrapper .base-input {
    font-size: 16px;
    min-width: 80px;
    max-height: 30px;
    padding: 4px 12px;
    margin: 0;
    box-shadow: 0 0 #8a8a8a;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
  }

  .item-wrapper .base-input {
    width: 230px;
  }

  .item-wrapper .route-cost-input {
    font-size: 16px;
    width: 60px;
    max-height: 30px;
    padding: 4px 12px;
    margin: 0;
    box-shadow: 0 0 #8a8a8a;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
  }

  .item-wrapper .ss-group .ss-input {
    font-size: 16px;
    min-width: 140px;
    padding: 0 12px;
    margin: 10px 12px 0px 15px;
    box-shadow: 0 0 #8a8a8a;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
  }

  .item-wrapper .base-input:hover,
  .item-wrapper .ss-group .ss-input:hover {
    border: 1px solid #29a7df;
  }

  .clickable {
    cursor: pointer;
  }

  .circle {
    display: inline-block;
    border-radius: 20px;
    padding: 5px 5px 2px 4px;
    width: 25px;
    height: 25px;
    align-items: center;
  }

  .route-name {
    text-align: right;
    padding: 0 24px;
  }

  .color {
    color: #bbb;
    font-size: 16px;
  }

  .color-wrap {
    width: 90px;
    height: 12px;
    border-radius: 6px;
    display: inline-block;
    box-shadow: 3px 3px 9px #3f6e8355;
  }
}