.oritcr-wrapper {
    display: flex;
    flex-direction: column;
    margin: 50px 0;
}

.oritcr-title {
    font-size: 30px;
    color: #1076ba;
    font-weight: 600;
}

.oritcr-container {
    display: flex;
    border: 1px #8a8a8a solid;
    padding: 15px;
}

.oritcr-modal-btn {
    color: white;
    font-weight: 600;
    background: #05b409;
    font-weight: 600;
    font-size: 16px;
    padding: 0 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.oritcr-editable {
    color: black;
    font-weight: 600;
    font-size: 16px;
    padding: 0 10px;
    margin: 0 20px;
    border-bottom: solid black 2px;
}

.oritcr-container .css-b62m3t-container{
    font-size: 16px;
    margin: 0px 10px 0px 24px;
    width: 200px;
}