.blur-filter-wrapper {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.blur-filter-msg-container {
  display: flex;
  flex-direction: column;
  border: solid 3px rgb(133, 129, 129);
  border-radius: 15px;
  width: 900px;
  background: white;
  z-index: 3;
}


.blur-filter-wrapper .btn-close-holder{
  display: flex;
  justify-content: center;
  margin: 4px 0 16px 0;
}

.blur-filter-wrapper .btn-close-holder .btn-close{
  padding: 2px 48px;
  cursor: pointer;
}

