.ul-list-wrapper {
    display: flex;
    flex-direction: column;
    max-height: 700px;
    overflow-y: auto;
    padding: 10px 0;
}

.ul-list-items-wrapper {
    /* display: flex; */
}

.ul-list-wrapper::-webkit-scrollbar {
    width: 8px;
}

.ul-list-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: #e2e2e2;
}

.ul-list-wrapper::-webkit-scrollbar-thumb {
    background: #666565;
    border-radius: 4px;
}

.ul-list-items-wrapper .no-list-items-text{
    padding: 24px;
    color: #fda73a;
}
.ul-list-items-wrapper .not-valid-search
{
    display: inline-block;
    padding: 6px 24px;
    color: #fd473a;
    border: solid 1px;
    border-radius: 6px;
    margin: 20px 0 0 0;
    font-size: 20px;
    background-color: #fefefe;
   
}

.users-list{
    display: inline-block;
    width: 300px;
    height: 300px;
    overflow: hidden;
    padding-top: 50px;
}
.users-list .spinner-wrapper{
    width: 164px;
    height: 164px;
    zoom: 1;

}
.users-list .spinner-wrapper circle{  stroke-width: 11; stroke: #2faadf; }