.station-edit {
  padding: 10px 40px;

  .ss-container {
    padding: 20px 0;
  }

  .area-code-limit-msg {
    font-size: 16px;
    font-weight: bold;
    color: red;
    margin-top: 6px;
  }

  .alt-stations-section {
    display: flex;
    margin: 20px 0px;
    gap: 16px;

    .container-select {
      width: 300px;
    }
  }

  .container-select.route-select,
  .container-select.city-select {
    display: inline-block;
    min-width: 822px;
    font-size: 16px;
  }

  .header {
    display: flex;

    .btn-branch-card {
      padding: 6px 8px;
      font-size: 16px;
      margin-right: 2vw;
    }
  }

  .ss-button {
    color: #fff;
  }

  .address-eng {
    input {
      direction: ltr;
    }
  }

  .area-code-select {
    display: inline-block;
    min-width: 822px;
  }

  .checkbox-holder {
    display: flex;
    margin: 8px 0 8px 0;

    .chebox-container {
      padding: 12px 16px;
      border: 1px solid grey;
      border-radius: 8px;
    }

    div:first-child {
      margin-left: 30px;
    }
  }
}